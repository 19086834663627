import { memo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
// Redux-Settings
import {
  selectCartList,
  selectCartVendorList,
  setCartList,
  setCartVendorList,
  selectSelectedCartItemsList,
  setSelectedCartItemsList,
  selectSelectedCartItemsId,
  setSelectedCartItemsId,
} from "../../redux/cartSlice";

// Images
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import PCoin from "../../assets/P-Coin.png";
// Utility
import { priceCalculator } from "../../utils/Utility";
import {
  addWishlistService,
  movetoWishlistService,
} from "../../service/wishlist.service";
import toast, { Toaster } from "react-hot-toast";

// Components
import RemoveCartModal from "./Modals/RemoveCartModal";

// Styles
import "../../assets/scss/base/pages/cart/OrderedItemsList.scss";
import {
  cartListService,
  cartListVendorService,
  cartItemRemoveService,
  updateProductQuantity,
} from "../../service/cart.service";
import { useEffect } from "react";
import { selectPCoinValue } from "../../redux/utilitySlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const OrderedItemsList = memo(({ setLoading, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const cartItems = useSelector(selectCartList);
  const cartVendorItems = useSelector(selectCartVendorList);
  const perPCoinValue = useSelector(selectPCoinValue);

  // Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [isMovedToWishlist, setIsMovedToWishlist] = useState(false);
  const [isRemovedFromCart, setIsRemovedFromCart] = useState(false);
  const [loadingMinus, setLoadingMinus] = useState(false);

  const [error, setError] = useState(false);
  const [isQuantityUpdate, setIsQuantityUpdate] = useState(false);

  const toggle = () => setIsOpenModal(!isOpenModal);
  // Cart-Item Select Settings
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
  const selectedCartItemsId = useSelector(selectSelectedCartItemsId);
  // const [selectedCartItemsId, setSelectedCartItemsId] = useState([]);
  // Check-cart-item-selection Settings
  function isAllhaveCommonPaymentMethod() {
    // Extract paymentMethod arrays from the data array
    const paymentMethodArrays = selectedCartItemsList.map(
      (item) => item?.Product?.paymentMethods
    );
    // Check if all arrays have at least one common element
    paymentMethodArrays.every((methodArray, index) => {
      // Compare each methodArray with the first methodArray
      if (index === 0) {
        return true;
      }
      // Check if there is at least one common element between methodArray and the first methodArray
      return methodArray.some((method) =>
        paymentMethodArrays[0].includes(method)
      );
    });

    // // Extract the paymentMethod array from the new item
    // const newItemPaymentMethod = newItem?.Product?.paymentMethods;
    //   // If the data array is empty, consider the new item as valid
    //   if (data.length === 0) {
    //     return true;
    //   }
    // // Check if any of the previous items have a common value with the new item
    // for (const item of data) {
    //   const existingPaymentMethod = item.Product.paymentMethods;
    //   const commonValues = newItemPaymentMethod.filter(value => existingPaymentMethod.includes(value));
    //   if (commonValues.length > 0) {
    //     return true; // The new item contains a value already present in previous items
    //   }
    // }
    // return false; // The new item is valid
  }

  const handleCartItemSelection = (cartItem) => {
    if (selectedCartItemsId.includes(cartItem?.id)) {
      // If the cart-item is already selected, remove it
      dispatch(
        setSelectedCartItemsList(
          selectedCartItemsList.filter(
            (selectedCartItem) => selectedCartItem?.id !== cartItem?.id
          )
        )
      );
      dispatch(
        setSelectedCartItemsId(
          selectedCartItemsId.filter((id) => id !== cartItem?.id)
        )
      );
    } else {
      // If the cart-item is not selected, add it
      dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
      dispatch(setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]));
    }
    // if(isNewItemValid(cartItem, selectedCartItemsList)){
    //   if(selectedCartItemsId.includes(cartItem?.id)){
    //   // If the cart-item is already selected, remove it
    //     dispatch(setSelectedCartItemsList(selectedCartItemsList.filter((selectedCartItem) => selectedCartItem?.id !== cartItem?.id)));
    //     dispatch(setSelectedCartItemsId(selectedCartItemsId.filter((id) => id !== cartItem?.id)));
    //   } else {
    //     // If the cart-item is not selected, add it
    //     dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
    //     setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]);
    //   }
    // }
  };

  // const handleCartItemSelection = (cartItem) => {
  //   if (_.includes(selectedCartItemsId?.map(item => parseInt(item)), parseInt(cartItem?.id))) {
  //     // If the cart-item is already selected, remove it
  //     dispatch(
  //       setSelectedCartItemsList(
  //         selectedCartItemsList.filter(
  //           (selectedCartItem) => parseInt(selectedCartItem?.id) !== parseInt(cartItem?.id)
  //         )
  //       )
  //     );
  //     dispatch(
  //       setSelectedCartItemsId(
  //         selectedCartItemsId.filter((id) => parseInt(id) !== parseInt(cartItem?.id))
  //       )
  //     );
  //   } else {
  //     // If the cart-item is not selected, add it
  //     dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
  //     dispatch(setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]));
  //   }
  //   // if(isNewItemValid(cartItem, selectedCartItemsList)){
  //   //   if(selectedCartItemsId.includes(cartItem?.id)){
  //   //   // If the cart-item is already selected, remove it
  //   //     dispatch(setSelectedCartItemsList(selectedCartItemsList.filter((selectedCartItem) => selectedCartItem?.id !== cartItem?.id)));
  //   //     dispatch(setSelectedCartItemsId(selectedCartItemsId.filter((id) => id !== cartItem?.id)));
  //   //   } else {
  //   //     // If the cart-item is not selected, add it
  //   //     dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
  //   //     setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]);
  //   //   }
  //   // }
  // };

  const handleVendorItemSelection = (status, vendorItem) => {
    if (status) {
      dispatch(
        setSelectedCartItemsList(
          cartItems.filter(
            (selectedCartItem) => _.includes(_.union(selectedCartItemsId?.map(item => parseInt(item)), vendorItem?.items?.map(item => parseInt(item?.id))), parseInt(selectedCartItem?.id))
          )
        )
      );
      dispatch(
        setSelectedCartItemsId(
          _.union(selectedCartItemsId?.map(item => parseInt(item)), vendorItem?.items?.map(item => parseInt(item?.id)))
        )
      );
    } else {
      dispatch(
        setSelectedCartItemsList(
          cartItems.filter(
            (selectedCartItem) => _.includes(_.difference(selectedCartItemsId?.map(item => parseInt(item)), vendorItem?.items?.map(item => parseInt(item?.id))), parseInt(selectedCartItem?.id))
          )
        )
      );
      dispatch(
        setSelectedCartItemsId(
          _.difference(selectedCartItemsId?.map(item => parseInt(item)), vendorItem?.items?.map(item => parseInt(item?.id)))
        )
      );
    }

    // if(isNewItemValid(cartItem, selectedCartItemsList)){
    //   if(selectedCartItemsId.includes(cartItem?.id)){
    //   // If the cart-item is already selected, remove it
    //     dispatch(setSelectedCartItemsList(selectedCartItemsList.filter((selectedCartItem) => selectedCartItem?.id !== cartItem?.id)));
    //     dispatch(setSelectedCartItemsId(selectedCartItemsId.filter((id) => id !== cartItem?.id)));
    //   } else {
    //     // If the cart-item is not selected, add it
    //     dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
    //     setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]);
    //   }
    // }
  };

  // Quantity Incress/Decress Settings
  const handleFieldChange = async (
    name,
    index,
    value,
    id,
    productId,
    productVariationId
  ) => {
    //api call of update quantity

    setIsQuantityUpdate(true);
    setLoading(true);
    const response = await updateProductQuantity(
      id,
      productId,
      productVariationId,
      value
    );
    if (!response.data.success) {
      setError(id);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              {/* <p>This product is not moved to wishlist</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
    // if (selectedCartItemsList?.some((obj) => obj.id == id)) {
    //   const newArr = selectedCartItemsList.map((item) => {
    //     if (item?.id == id) {
    //       return { ...item, [name]: value };
    //     }
    //     return item;
    //   });
    //   dispatch(setSelectedCartItemsList(newArr));
    // }
    // const newArr = cartItems.map((item, i) => {
    //   if (index == i) {
    //     return { ...item, [name]: value };
    //   }
    //   return item;
    // });
    // dispatch(setCartList(newArr));
    setLoading(false);
  };

  useEffect(() => {
    const fetchCartList = async () => {
      setIsQuantityUpdate(false);
      const response = await cartListService({ filter: "" });
      // const response2 = await cartListVendorService({ filter: '' });
      if (response?.data?.success) {
        dispatch(setCartList(response?.data?.data?.list));
        dispatch(setSelectedCartItemsList(response?.data?.data?.list));
        dispatch(
          setSelectedCartItemsId(
            response?.data?.data?.list?.map((item) => item?.id)
          )
        );
      }
      // if (response2?.data?.success) {
      //   dispatch(setCartVendorList(response2?.data?.data))
      // }
    };
    fetchCartList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuantityUpdate, isMovedToWishlist, isRemovedFromCart]);
  //Move to wishlist
  const handleMoveProductWishlist = async (cartItem) => {
    const respons = await movetoWishlistService({
      productId: Number(cartItem?.id),
      productVariationsId: cartItem?.productVariationId,
    });
    if (respons?.data?.success) {
      setIsMovedToWishlist(`${cartItem?.id}` + true);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully moved to wishlist!</h4>
              <p>This product is successfully moved to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{respons?.data?.errorMessage}</h4>
              <p>This product is not moved to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  return (
    <>
      <div className="frame-parent11OrderedItemsList" style={{ height: '620px', overflowY: 'auto' }}>
        <div className="checkbox-base-parentOrderedItemsList">
          <FormGroup check>
            <Input
              className="checkbox-allOrderedItemsList"
              type="checkbox"
              checked={selectedCartItemsId?.length > 0}
            />
          </FormGroup>
          <div className="items-selectedOrderedItemsList">
            {selectedCartItemsId?.length} /{cartItems?.length} items selected
          </div>
        </div>
        {/* {cartVendorItems?.map((vendorItem) => {
          return <div key={vendorItem?.vendor?.id} className="frame-parent14VendorItemsList">
            <div className="d-flex align-items-center bg-white p-2">
              <FormGroup check className="ps-0 d-flex align-items-center me-2">
                <Input
                  className="vendor-baseOrderedItemsList"
                  type="checkbox"
                  value={vendorItem?.vendor?.id}
                  checked={selectedCartItemsId?.length > 0 && vendorItem?.items?.length > 0 ? _.intersection(selectedCartItemsId?.map(item => parseInt(item)), vendorItem?.items?.map(item => parseInt(item?.id))) : false}
                  onChange={(e) => handleVendorItemSelection(e?.target?.checked, vendorItem)}
                />
              </FormGroup>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 8.65685 16.3431 10 18 10C19.6569 10 21 8.65685 21 7M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#D71513" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className="vendor-name ms-2">{vendorItem?.vendor?.firstName || "-"}</p>
            </div>
            {vendorItem?.items?.length > 0 && vendorItem?.items?.map((cartItem, index) => {
              return <div key={cartItem?.id} className="frame-parent14OrderedItemsList">
                <div
                  className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
                  onClick={() => navigate(`/product/${cartItem?.Product?.slug}`)}
                >
                  {cartItem?.Product?.thumbnail ? (
                    <img
                      className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                      alt="item"
                      src={`${imageCDN}/${cartItem?.Product?.thumbnail}`}
                    />
                  ) : (
                    <img
                      className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                      alt="item"
                      src={ProductPlaceHolder}
                    />
                  )}
                </div>
                <FormGroup check>
                  <Input
                    className="checkbox-baseOrderedItemsList"
                    type="checkbox"
                    value={cartItem.id}
                    checked={_.includes(selectedCartItemsId?.map(item => parseInt(item)), parseInt(cartItem?.id))}
                    onChange={() => handleCartItemSelection(cartItem)}
                  />
                </FormGroup>
                <div className="frame-parent15OrderedItemsList">
                  <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
                    <div
                      className="hp-pavilion-14-dv2029tuOrderedItemsList"
                      onClick={() =>
                        navigate(`/product/${cartItem?.Product?.slug}`)
                      }
                    >
                      {cartItem?.Product?.name}
                    </div>

                    <div
                      className="parentOrderedItemsList"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        className="rm290900OrderedItemsList"
                        style={{ display: "flex", fontWeight: "700" }}
                      >
                        <p style={{ fontSize: "11px" }}>RM</p>
                        {Number(
                          priceCalculator({
                            price: cartItem?.ProductVariation?.price,
                            discountPrice: cartItem?.ProductVariation?.discount,
                          })
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      {cartItem?.ProductVariation?.discount > 0 && (
                        <div
                          className="divOrderedItemsList"
                          style={{ fontSize: "11px", marginTop: "2px" }}
                        >
                          RM
                          {cartItem?.ProductVariation?.price.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </div>
                      )}

                      <div
                        className="offOrderedItemsList"
                        style={{ fontSize: "11px", marginTop: "2px" }}
                      >
                        {cartItem?.ProductVariation?.discount > 0 &&
                          Math.floor(cartItem?.ProductVariation?.discount) +
                          "% OFF"}
                      </div>
                    </div>
                  </div>
                  <div className="frame-parent13OrderedItemsList">
                    {cartItem?.ProductVariation?.attibutes?.Color !== "Default" &&
                      cartItem?.ProductVariation?.attibutes?.Color !== "other" && (
                        <div className="coloringDivOrderedItemsList">
                          <p className="colorTitleOrderedItemsList">Color:</p>
                          <div
                            style={{
                              border: "2px solid #d3d3d3",
                              height: 20,
                              width: 20,
                              borderRadius: 20,
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                height: 16,
                                width: 16,
                                borderRadius: 20,
                                backgroundColor: cartItem?.ProductVariation
                                  ?.attibutes?.Color
                                  ? cartItem?.ProductVariation?.attibutes?.Color?.toLowerCase()
                                  : cartItem?.ProductVariation?.attibutes?.color?.toLowerCase(),
                              }}
                            />
                          </div>
                          <p style={{ textTransform: "capitalize" }}>
                            {cartItem?.ProductVariation?.attibutes?.Color
                              ? cartItem?.ProductVariation?.attibutes?.Color
                              : cartItem?.ProductVariation?.attibutes?.color}
                          </p>
                        </div>
                      )}
                    {(cartItem?.ProductVariation?.attibutes?.Size ||
                      cartItem?.ProductVariation?.attibutes?.storage) && (
                        <div className="coloringDivOrderedItemsList">
                          <p className="colorTitleOrderedItemsList">Size:</p>
                          <p>
                            {cartItem?.ProductVariation?.attibutes?.Size
                              ? cartItem?.ProductVariation?.attibutes?.Size + " "
                              : null}
                            {cartItem?.ProductVariation?.attibutes?.storage
                              ? cartItem?.ProductVariation?.attibutes?.storage
                              : null}
                          </p>
                        </div>
                      )}
                  </div>
                  <div className="quantity-1-parentOrderedItemsList">
                    <div className="blackOrderedItemsList">
                      Quantity :
                      <span
                        className={loading && "quantity__controllerr"}
                        onClick={() =>
                          handleFieldChange(
                            "quantity",
                            index,
                            Number(cartItem?.quantity) === 1
                              ? cartItem?.quantity
                              : Number(cartItem?.quantity) - 1,
                            cartItem?.id,
                            cartItem?.productId,
                            cartItem?.productVariationId
                          )
                        }
                      >
                        -
                      </span>
                      <span className="product__quantity">
                        {cartItem?.quantity}
                      </span>
                      <span
                        className={
                          (loading ||
                            cartItem?.ProductVariation?.stock ==
                            cartItem?.quantity) &&
                          "quantity__controllerr"
                        }
                        onClick={() =>
                          handleFieldChange(
                            "quantity",

                            index,
                            Number(cartItem?.quantity) + 1,
                            cartItem?.id,
                            cartItem?.productId,
                            cartItem?.productVariationId
                          )
                        }
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <p className="Cart__Item__PCoin">
                    Redeemable Pcoins :{" "}
                    {priceCalculator({
                      price: cartItem?.ProductVariation?.price,
                      discountPrice: cartItem?.ProductVariation?.discount,
                    }) *
                      perPCoinValue <
                      cartItem?.ProductVariation?.redeemPcoinsLimit
                      ? parseFloat(
                        priceCalculator({
                          price: cartItem?.ProductVariation?.price,
                          discountPrice: cartItem?.ProductVariation?.discount,
                        }) * perPCoinValue
                      ) * cartItem?.quantity
                      : cartItem?.ProductVariation?.redeemPcoinsLimit *
                      cartItem?.quantity}
                  </p>
                  <p className="Cart__Item__PCoin">
                    Available Stock : {cartItem?.ProductVariation?.stock}
                  </p>
                  <div className="remove-groupOrderedItemsList">
                    <div
                      onClick={() => {
                        toggle();
                        setFormValue(cartItem);
                      }}
                      className="remove2OrderedItemsList"
                    >
                      Remove
                    </div>
                    <img
                      className="frame-child3OrderedItemsList"
                      alt=""
                      src="/vector-1701.svg"
                    />
                    <div
                      className="move-to-wishlist1OrderedItemsList"
                      onClick={() => handleMoveProductWishlist(cartItem)}
                    >
                      Move to Wishlist
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        })} */}
        {cartItems?.map((cartItem, index) => (
          <div key={cartItem?.id} className="frame-parent14OrderedItemsList">
            <div
              className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
              onClick={() => navigate(`/product/${cartItem?.Product?.slug}`)}
            >
              {cartItem?.Product?.thumbnail ? (
                <img
                  className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                  alt="item"
                  src={`${imageCDN}/${cartItem?.Product?.thumbnail}`}
                />
              ) : (
                <img
                  className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                  alt="item"
                  src={ProductPlaceHolder}
                />
              )}
            </div>
            <FormGroup check>
              <Input
                className="checkbox-baseOrderedItemsList"
                type="checkbox"
                value={cartItem.id}
                checked={selectedCartItemsId.includes(cartItem.id)}
                onChange={() => handleCartItemSelection(cartItem)}
              />
            </FormGroup>
            <div className="frame-parent15OrderedItemsList">
              <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
                <div
                  className="hp-pavilion-14-dv2029tuOrderedItemsList"
                  onClick={() =>
                    navigate(`/product/${cartItem?.Product?.slug}`)
                  }
                >
                  {cartItem?.Product?.name}
                </div>

                <div
                  className="parentOrderedItemsList"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className="rm290900OrderedItemsList"
                    style={{ display: "flex", fontWeight: "700" }}
                  >
                    <p style={{ fontSize: "11px" }}>RM</p>
                    {Number(
                      priceCalculator({
                        price: cartItem?.ProductVariation?.price,
                        discountPrice: cartItem?.ProductVariation?.discount,
                      })
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  {cartItem?.ProductVariation?.discount > 0 && (
                    <div
                      className="divOrderedItemsList"
                      style={{ fontSize: "11px", marginTop: "2px" }}
                    >
                      RM
                      {cartItem?.ProductVariation?.price.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </div>
                  )}

                  <div
                    className="offOrderedItemsList"
                    style={{ fontSize: "11px", marginTop: "2px" }}
                  >
                    {cartItem?.ProductVariation?.discount > 0 &&
                      Math.floor(cartItem?.ProductVariation?.discount) +
                      "% OFF"}
                  </div>
                </div>
              </div>
              <div className="frame-parent13OrderedItemsList">
                {cartItem?.ProductVariation?.attibutes?.Color !== "Default" &&
                  cartItem?.ProductVariation?.attibutes?.Color !== "other" && (
                    <div className="coloringDivOrderedItemsList">
                      <p className="colorTitleOrderedItemsList">Color:</p>
                      <div
                        style={{
                          border: "2px solid #d3d3d3",
                          height: 20,
                          width: 20,
                          borderRadius: 20,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 20,
                            backgroundColor: cartItem?.ProductVariation
                              ?.attibutes?.Color
                              ? cartItem?.ProductVariation?.attibutes?.Color?.toLowerCase()
                              : cartItem?.ProductVariation?.attibutes?.color?.toLowerCase(),
                          }}
                        />
                      </div>
                      <p style={{ textTransform: "capitalize" }}>
                        {cartItem?.ProductVariation?.attibutes?.Color
                          ? cartItem?.ProductVariation?.attibutes?.Color
                          : cartItem?.ProductVariation?.attibutes?.color}
                      </p>
                    </div>
                  )}
                {(cartItem?.ProductVariation?.attibutes?.Size ||
                  cartItem?.ProductVariation?.attibutes?.storage) && (
                    <div className="coloringDivOrderedItemsList">
                      <p className="colorTitleOrderedItemsList">Size:</p>
                      <p>
                        {cartItem?.ProductVariation?.attibutes?.Size
                          ? cartItem?.ProductVariation?.attibutes?.Size + " "
                          : null}
                        {cartItem?.ProductVariation?.attibutes?.storage
                          ? cartItem?.ProductVariation?.attibutes?.storage
                          : null}
                      </p>
                    </div>
                  )}
              </div>
              <div className="quantity-1-parentOrderedItemsList">
                <div className="blackOrderedItemsList">
                  Quantity :
                  <span
                    className={loading && "quantity__controllerr"}
                    onClick={() =>
                      handleFieldChange(
                        "quantity",
                        index,
                        Number(cartItem?.quantity) === 1
                          ? cartItem?.quantity
                          : Number(cartItem?.quantity) - 1,
                        cartItem?.id,
                        cartItem?.productId,
                        cartItem?.productVariationId
                      )
                    }
                  >
                    -
                  </span>
                  <span className="product__quantity">
                    {cartItem?.quantity}
                  </span>
                  <span
                    className={
                      (loading ||
                        cartItem?.ProductVariation?.stock ==
                        cartItem?.quantity) &&
                      "quantity__controllerr"
                    }
                    onClick={() =>
                      handleFieldChange(
                        "quantity",

                        index,
                        Number(cartItem?.quantity) + 1,
                        cartItem?.id,
                        cartItem?.productId,
                        cartItem?.productVariationId
                      )
                    }
                  >
                    +
                  </span>
                </div>
              </div>
              <p className="Cart__Item__PCoin">
                Redeemable Pcoins :{" "}
                {priceCalculator({
                  price: cartItem?.ProductVariation?.price,
                  discountPrice: cartItem?.ProductVariation?.discount,
                }) *
                  perPCoinValue <
                  cartItem?.ProductVariation?.redeemPcoinsLimit
                  ? parseFloat(
                    priceCalculator({
                      price: cartItem?.ProductVariation?.price,
                      discountPrice: cartItem?.ProductVariation?.discount,
                    }) * perPCoinValue
                  ) * cartItem?.quantity
                  : cartItem?.ProductVariation?.redeemPcoinsLimit *
                  cartItem?.quantity}
              </p>
              <p className="Cart__Item__PCoin">
                Available Stock : {cartItem?.ProductVariation?.stock}
              </p>
              <div className="remove-groupOrderedItemsList">
                <div
                  onClick={() => {
                    toggle();
                    setFormValue(cartItem);
                  }}
                  className="remove2OrderedItemsList"
                >
                  Remove
                </div>
                <img
                  className="frame-child3OrderedItemsList"
                  alt=""
                  src="/vector-1701.svg"
                />
                <div
                  className="move-to-wishlist1OrderedItemsList"
                  onClick={() => handleMoveProductWishlist(cartItem)}
                >
                  Move to Wishlist
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isOpenModal && (
        <RemoveCartModal
          isOpenModal={isOpenModal}
          toggle={toggle}
          formValue={formValue}
          setIsRemovedFromCart={setIsRemovedFromCart}
        />
      )}
    </>
  );
});

export default OrderedItemsList;