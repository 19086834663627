import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FormGroup, Label, Input } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";

// API-Service
import { addressListService } from "../../service/address.service";
// Redux-Settings
import {
  setShippingAddressList,
  setBillingAddressList,
  selectShippingAddressList,
  selectBillingAddressList,
  setIsAsDefaultAsShippingAddress,
  selectIsAsDefaultAsShippingAddress,
  setDefaultShippingAddressId,
  setDefaultBillingAddressId,
  selectDefaultShippingAddressId,
} from "../../redux/addressSlice";

// Components
import ShippingAddressListingModal from "./Modals/ShippingAddressListingModal";
import BillingAddressListingModal from "./Modals/BillingAddressListingModal";

// Styles
import "../../assets/scss/base/pages/cart/DeliveryAddressListing.scss";
import {
  selectSelectedCartItemsList,
  selectSelfPickup,
  setShipingCost,
  setWeight,
} from "../../redux/cartSlice";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import RedCross from "../../assets/images/cart/red-cross-cercle.png";
import { getShippingCostService } from "../../service/cart.service";

const DeliveryAddressListing = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
  const isSelfPickup = useSelector(selectSelfPickup);
  const defultShippingID = useSelector(selectDefaultShippingAddressId);

  const shippingAddressList = useSelector(selectShippingAddressList);
  const billingAddressList = useSelector(selectBillingAddressList);
  const asDefaultasShippingAddress = useSelector(
    selectIsAsDefaultAsShippingAddress
  );
  // Update Modal-Controll
  const [
    isOpenShippingAddressListingModal,
    setIsOpenShippingAddressListingModal,
  ] = useState(false);
  const shippingAddressListingModaltoggle = () =>
    setIsOpenShippingAddressListingModal(!isOpenShippingAddressListingModal);

  const [
    isOpenBillingAddressListingModal,
    setIsOpenBillingAddressListingModal,
  ] = useState(false);
  const billingAddressListingModaltoggle = () =>
    setIsOpenBillingAddressListingModal(!isOpenBillingAddressListingModal);
  const [addAddressClicked, setAddAddressClicked] = useState(false);
  const [updateAddressClicked, setUpdateAddressClicked] = useState(false);

  const fetchShippingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Shipping",
    });
    if (response?.data?.success) {
      dispatch(setShippingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultShippingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const fetchBillingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Billing",
    });
    if (response?.data?.success) {
      dispatch(setBillingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultBillingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };
  useEffect(() => {
    fetchShippingAddressList();
    fetchBillingAddressList();
  }, []);

  useEffect(() => {
    if (asDefaultasShippingAddress) {
      dispatch(
        setDefaultBillingAddressId(
          shippingAddressList?.find((item) => item?.isDefault)?.id || ""
        )
      );
    } else {
      dispatch(
        setDefaultBillingAddressId(
          billingAddressList?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  }, [asDefaultasShippingAddress]);
  const totalItemWeight = () => {
    let totalWeight = 0;
    selectedCartItemsList.forEach((item) => {
      totalWeight = totalWeight + item?.quantity * item.ProductVariation.weight;
    });
    dispatch(setWeight(totalWeight));

    return totalWeight;
  };
  const fetchShippingCost = async (id, weight) => {
    if (defultShippingID) {
      const response = await getShippingCostService({
        id,
        weight,
      });
      if (response?.data?.success) {
        dispatch(setShipingCost(response?.data?.data?.price));
      } else {
        dispatch(setShipingCost(0));
        if (addAddressClicked || updateAddressClicked) {
          toast(
            (t) => (
              <div className="toast__block">
                <div className="toast__block__icon">
                  <img src={AlertCircle} alt="" />
                </div>
                <div className="toast__block__content">
                  <h4>{response?.data?.errorMessage}</h4>
                </div>

                <button onClick={() => toast.dismiss(t.id)}>
                  <img src={XClose} alt="" />
                </button>
              </div>
            ),
            {
              style: {
                borderRadius: "10px",
                background: "#D92D20",
                color: "#FFD7D3",
              },
            }
          );
        }
      }
    }
  };
  useEffect(() => {
    fetchShippingCost(defultShippingID, totalItemWeight());
  }, [selectedCartItemsList, isSelfPickup, shippingAddressList]);

  return (
    <div className="frame-parent5DeliveryAddressListing">
      <div className="select-delivery-address-parentDeliveryAddressListing">
        {location.pathname === "/account/addressBook" ? null : (
          <h2 className="select-delivery-addressDeliveryAddressListing">
            Shipping and Billing Address
          </h2>
        )}
      </div>
      <div className="address__block">
        <div className="address__title__block">
          <h3>Shipping Address</h3>
          {shippingAddressList?.some((obj) => obj?.isDefault === true) ? (
            <button onClick={shippingAddressListingModaltoggle}>
              Change Address
            </button>
          ) : (
            <button onClick={shippingAddressListingModaltoggle}>
              Add Address
            </button>
          )}
        </div>
        <div className="address__item__block">
          {shippingAddressList.some((obj) => obj?.isDefault === true) ? (
            <>
              {shippingAddressList?.map((address) => {
                if (address?.isDefault) {
                  return (
                    <div className="frame-parent6DeliveryAddressListing">
                      <div className="sinan-afthar-parentDeliveryAddressListing">
                        <div className="sinan-aftharDeliveryAddressListing">
                          {address?.receiptient}
                        </div>
                        <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                            {address?.company}
                          </p>
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                            {address?.tin}
                          </p>
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                            {address?.address}
                          </p>
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                            {address?.city}
                          </p>
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                            {address?.state}, {address?.zipCode} -{" "}
                            {address?.country}
                          </p>

                        </div>
                        <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                          <span>{`Mobile : `}</span>
                          <span className="spanDeliveryAddressListing">
                            +6 {address?.mobile}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <div className="address__empty__block">
              <h3>There is no shipping address found!</h3>
            </div>
          )}
        </div>
      </div>
      <FormGroup check>
        <Input
          name="isDefaultAsShippingAddress"
          id="isDefaultAsShippingAddress"
          type="checkbox"
          defaultChecked={true}
          checked={asDefaultasShippingAddress}
          onChange={(e) =>
            dispatch(setIsAsDefaultAsShippingAddress(e?.target?.checked))
          }
        />
        <Label for="isDefaultAsShippingAddress" check>
          Billing address is the same as Shipping address
        </Label>
      </FormGroup>
      {!asDefaultasShippingAddress && (
        <div className="address__block">
          <div className="address__title__block">
            <h3>Billing Address</h3>
            {billingAddressList.some((obj) => obj?.isDefault === true) ? (
              <button onClick={billingAddressListingModaltoggle}>
                Change Address
              </button>
            ) : (
              <button onClick={billingAddressListingModaltoggle}>
                Add Address
              </button>
            )}
          </div>
          <div className="address__item__block">
            {billingAddressList.some((obj) => obj?.isDefault === true) ? (
              <>
                {billingAddressList?.map((address) => {
                  if (address?.isDefault) {
                    return (
                      <div className="frame-parent6DeliveryAddressListing">
                        <div className="sinan-afthar-parentDeliveryAddressListing">
                          <div className="sinan-aftharDeliveryAddressListing">
                            {address?.receiptient}
                          </div>
                          <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.company}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.tin}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.address}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.city}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.state}, {address?.zipCode} -{" "}
                              {address?.country}
                            </p>

                          </div>
                          <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                            <span>{`Mobile : `}</span>
                            <span className="spanDeliveryAddressListing">
                              +6 {address?.mobile}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <div className="address__empty__block">
                <h3>There is no billing address found!</h3>
              </div>
            )}
          </div>
        </div>
      )}
      {isOpenShippingAddressListingModal && (
        <ShippingAddressListingModal
          isOpenShippingAddressListingModal={isOpenShippingAddressListingModal}
          shippingAddressListingModaltoggle={shippingAddressListingModaltoggle}
          setAddAddressClicked={setAddAddressClicked}
          setUpdateAddressClicked={setUpdateAddressClicked}
        />
      )}
      {isOpenBillingAddressListingModal && (
        <BillingAddressListingModal
          isOpenBillingAddressListingModal={isOpenBillingAddressListingModal}
          billingAddressListingModaltoggle={billingAddressListingModaltoggle}
        />
      )}
    </div>
  );
});

export default DeliveryAddressListing;
