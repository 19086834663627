import { memo, useState, useEffect } from "react";
import ReactGA from "react-ga4";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  pcoin,
  coupon_sign,
  uparrow,
  CrossIcon,
} from "../../assets/images/cart";
import toast, { Toaster } from "react-hot-toast";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

// Componets
import VouchersModal from "./Modals/VouchersModal";
import PCoinReddemModal from "./Modals/PCoinReddemModal";
import EarnCoinsForm from "./EarnCoinsForm";

// API-Service
import {
  applyCouponService,

  cartListService,
  PaymentService,

} from "../../service/cart.service";
// Utility
import { priceCalculator } from "../../utils/Utility";
// Redux-Settings
import {
  selectSelectedCartItemsList,
  setSubtotalAmount,
  setDiscountAmount,
  selectSubtotalAmount,
  selectDiscountAmount,
  setPCoinAmount,
  selectPCoinAmount,
  selectVoucherContent,
  selectVoucherDiscountAmount,
  setCartPageTabIndex,
  selectCartPageTabIndex,
  setIsAppliedPCoin,
  setIsAppliedCoupon,
  selectIsAppliedVoucher,
  selectIsAppliedCoupon,
  selectIsAppliedPCoin,
  setIsAppliedVoucher,
  setVoucherDiscountAmount,
  setpCoinDiscountAmount,
  selectpCoinDiscountAmount,
  setRedeemedpCoin,
  selectReedemedpCoin,
  selectCouponContent,
  setCouponContent,
  setCouponDiscountAmount,
  selectCouponDiscountAmount,
  selectShipingCost,
  setCartList,
  setSelectedCartItemsList,
  selectCartVendorList,
  setSelectedCartItemsId,

  selectDeepLink,
  selectWeight,

  selectPaymentMethod,
  selectRequestEInvoice,
  selectEInvoiceType,
  setTotalProductDiscount,
  selectTotalAmount,
  selectTotalProductDiscount,
  setTotalAmount,
  selecttotalAmount,
  setTotalPcoinsBasedOnPrice,
  selectTotalPcoinsBasedOnPrice,
  setCouponType,
  selectCouponType,
  selectSelfPickup,
  setSelfPickup,
  setVoucherContent,

} from "../../redux/cartSlice";
import {

  selectPCoinValue,
} from "../../redux/utilitySlice";
import {
  selectDefaultShippingAddressId,
  selectDefaultBillingAddressId,
  selectIsAsDefaultAsShippingAddress,
  selectShippingAddressList,
  setSelectedAddressZipCode,
  selectAddressZipcode,
} from "../../redux/addressSlice";
// Styles
import "../../assets/scss/base/pages/cart/BillVoucherForm.scss";

// Images
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import RedCross from "../../assets/images/cart/red-cross-cercle.png";
import { selectPcoinBalance, selectUserDetails } from "../../redux/userSlice";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const BillVoucherForm = memo(() => {
  const user = useSelector(selectUserDetails);
  const totalAmount = useSelector(selecttotalAmount);
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
  const cartVendorItems = useSelector(selectCartVendorList);
  const subTotalAmount = useSelector(selectSubtotalAmount);
  const discountAmount = useSelector(selectDiscountAmount);
  const asDefaultasShippingAddress = useSelector(
    selectIsAsDefaultAsShippingAddress
  );
  const isSelfPickup = useSelector(selectSelfPickup);

  const selectedPaymentMethod = useSelector(selectPaymentMethod);
  const requestE_invoice = useSelector(selectRequestEInvoice);
  const E_invoiceType = useSelector(selectEInvoiceType);

  const deeplink = useSelector(selectDeepLink);

  // Voucher-Selectors
  const isVoucherApplied = useSelector(selectIsAppliedVoucher);
  const voucherContent = useSelector(selectVoucherContent);
  const voucherDiscountAmount = useSelector(selectVoucherDiscountAmount);
  // P-Coin-Selectors
  const PcoinBalance = useSelector(selectPcoinBalance);
  //shipping Addresses
  const ShippingAddressList = useSelector(selectShippingAddressList);

  const subTotalPCoinAmount = useSelector(selectPCoinAmount);
  const perPCoinValue = useSelector(selectPCoinValue);
  const isPCoinApplied = useSelector(selectIsAppliedPCoin);
  const discountpCoinAmount = useSelector(selectpCoinDiscountAmount);

  const redeempCoin = useSelector(selectReedemedpCoin);
  //Coupon-Selectors
  const couponType = useSelector(selectCouponType);
  const isCouponApplied = useSelector(selectIsAppliedCoupon);
  const couponContent = useSelector(selectCouponContent);
  const couponDiscountAmount = useSelector(selectCouponDiscountAmount);


  // Cartpage-Tab-Selection
  const cartPageTab = useSelector(selectCartPageTabIndex);
  const disPatch = useDispatch();
  //zipcode
  const zipCode = useSelector(selectAddressZipcode);

  // ShipingCost
  const shipingCost = useSelector(selectShipingCost);
  const weight = useSelector(selectWeight);
  // Select address
  const defultShippingID = useSelector(selectDefaultShippingAddressId);
  const defultBillingID = useSelector(selectDefaultBillingAddressId);
  const totalProductDiscount = useSelector(selectTotalProductDiscount);
  const totalPcoinsBasedOnPrice = useSelector(selectTotalPcoinsBasedOnPrice);
  // Modal-Controll
  const [isOpenVoucherModal, setIsOpenVoucherModal] = useState(false);
  const voucherModaltoggle = () => setIsOpenVoucherModal(!isOpenVoucherModal);

  const [isOpenPCoinReddemModal, setIsOpenPCoinReddemModal] = useState(false);
  const PCoinReddemModaltoggle = () =>
    setIsOpenPCoinReddemModal(!isOpenPCoinReddemModal);
  const [pCoinModalData, setpCoinModalData] = useState({});
  const [earningPCoin, setEarningPCoin] = useState(0);
  const [pCoinRedeem, setPCoinRedeem] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  // Calculation
  useEffect(() => {
    const calculatetotalAmount = selectedCartItemsList?.reduce(
      (sum, i) => i?.ProductVariation?.price * i?.quantity + sum,
      0
    );
    disPatch(setTotalAmount(calculatetotalAmount));
    const calculateSubtotalAmount = selectedCartItemsList?.reduce(
      (sum, i) =>
        priceCalculator({
          price: i?.ProductVariation?.price,
          discountPrice: i?.ProductVariation?.discount,
        }) *
        i?.quantity +
        sum,
      0
    );
    const discount = calculatetotalAmount - calculateSubtotalAmount;
    disPatch(setTotalProductDiscount(discount));

    disPatch(setSubtotalAmount(calculateSubtotalAmount));
    const pCoinAmount = selectedCartItemsList?.reduce(
      (sum, i) => i?.ProductVariation?.redeemPcoinsLimit + sum,
      0
    );

    disPatch(setPCoinAmount(pCoinAmount));
    const earnngPCoinAmount = selectedCartItemsList?.reduce(
      (sum, i) => i?.ProductVariation?.earningPcoins * i?.quantity + sum,
      0
    );

    // You can earn pcoin X P-Coins for completing this purchase

    setEarningPCoin(
      earnngPCoinAmount -
      (discountpCoinAmount +
        voucherDiscountAmount +
        (couponType === "Free Shipping" ? 0 : couponDiscountAmount))
    );

    disPatch(
      setDiscountAmount(
        couponDiscountAmount + discountpCoinAmount + voucherDiscountAmount
      )
    );
    const selectedAddress = ShippingAddressList.filter((addresses) => {
      if (addresses.id === defultShippingID) {
        return addresses;
      }
    });
    disPatch(setSelectedAddressZipCode(selectedAddress[0]?.zipCode));
  }, [
    selectedCartItemsList,
    shipingCost,
    isPCoinApplied,
    isVoucherApplied,
    isCouponApplied,
    cartPageTab,
    ShippingAddressList,
  ]);

  useEffect(() => {
    disPatch(setSubtotalAmount(subTotalAmount));
  }, [
    shipingCost,
    isPCoinApplied,
    isVoucherApplied,
    isCouponApplied,
    couponType,
    isSelfPickup,
    cartPageTab,
  ]);

  const handleApplyCoupon = async (e) => {
    e.preventDefault();

    if (isVoucherApplied) {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>
                You can redeem any one of discount either coupon or voucher per
                order
              </h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    } else {
      const couponCode = e?.target?.couponCode?.value;
      const response = await applyCouponService({
        shippingCost: shipingCost,
        cartItemsId: selectedCartItemsList?.map((cartItem) => cartItem?.id),
        code: couponCode,
        amount: subTotalAmount,
        zipCode: Number(zipCode),
      });
      if (response?.data?.success) {
        disPatch(setCouponContent(couponCode));
        disPatch(setCouponDiscountAmount(response?.data?.data?.discount));
        disPatch(setIsAppliedCoupon(true));
        disPatch(setCouponType(response?.data?.data?.type));

        // if (isSelfPickup && couponType == "Free Shipping") {
        //   disPatch(setDiscountAmount(0));
        //   disPatch(setRedeemedpCoin(0));
        //   disPatch(setIsAppliedPCoin(false));
        //   disPatch(setpCoinDiscountAmount(0));
        // }
        if (couponType == "Free Shipping") {
          disPatch(setSelfPickup(false));
        }

        e?.target?.reset();
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleIcon} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Successfully applied!</h4>
                <p>The coupon code is successfully applied</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
      } else {
        e?.target?.reset();
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={AlertCircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>{response?.data?.errorMessage}</h4>
                <p>This coupon can't applied</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    }
  };
  const handleApplyVoucher = (e) => {
    if (isCouponApplied) {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>
                You can redeem any one of discount either coupon or voucher per
                order
              </h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    } else {
      voucherModaltoggle();
    }
  };

  const handleReedeem = (e) => {
    e.preventDefault();

    if (pCoinRedeem >= perPCoinValue && pCoinRedeem % perPCoinValue == 0) {
      let userInputPCoin = "";
      if (pCoinRedeem) {
        userInputPCoin = pCoinRedeem;
      }

      if (
        userInputPCoin &&
        userInputPCoin <= PcoinBalance &&
        userInputPCoin <= totalPcoinsBasedOnPrice
      ) {
        disPatch(setRedeemedpCoin(userInputPCoin));
        disPatch(setDiscountAmount(userInputPCoin * (1 / perPCoinValue)));
        disPatch(setpCoinDiscountAmount(userInputPCoin * (1 / perPCoinValue)));
        disPatch(setIsAppliedPCoin(true));
        setpCoinModalData({
          PcoinBalance,
          subTotalPCoinAmount,
          userInputPCoin,
          isSuccess: true,
        });
        e?.target?.reset();
        PCoinReddemModaltoggle();
      } else {
        setpCoinModalData({
          PcoinBalance,
          subTotalPCoinAmount,
          userInputPCoin,
          isSuccess: false,
        });
        e?.target?.reset();
        PCoinReddemModaltoggle();
      }
    } else {
      e?.target?.reset();
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>
                Redeemed P-coin must be greater than or equal to and multiple
                {perPCoinValue}
              </h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  const handleMaxRedeem = (e) => {
    e.preventDefault();
    if (PcoinBalance >= perPCoinValue) {
      let divisbles = [];
      const maxRedeem =
        totalPcoinsBasedOnPrice > PcoinBalance
          ? PcoinBalance
          : totalPcoinsBasedOnPrice;
      for (let i = 0; i <= maxRedeem; i++) {
        if (i % perPCoinValue == 0) {
          divisbles.push(i);
        }
      }
      setPCoinRedeem(Math.max(...divisbles));
      //call redeem function
      let userInputPCoin = "";
      userInputPCoin = Math.max(...divisbles);

      if (
        userInputPCoin &&
        userInputPCoin <= PcoinBalance &&
        userInputPCoin <= totalPcoinsBasedOnPrice
      ) {
        disPatch(setRedeemedpCoin(userInputPCoin));
        disPatch(setDiscountAmount(userInputPCoin * (1 / perPCoinValue)));
        disPatch(setpCoinDiscountAmount(userInputPCoin * (1 / perPCoinValue)));
        disPatch(setIsAppliedPCoin(true));
        setpCoinModalData({
          PcoinBalance,
          subTotalPCoinAmount,
          userInputPCoin,
          isSuccess: true,
        });
        PCoinReddemModaltoggle();
      } else {
        setpCoinModalData({
          PcoinBalance,
          subTotalPCoinAmount,
          userInputPCoin,
          isSuccess: false,
        });
        PCoinReddemModaltoggle();
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>
                You should atleast have {perPCoinValue} p-coins for redeemption
              </h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  function isAllhaveCommonPaymentMethod() {
    // Extract paymentMethod arrays from the data array
    const paymentMethodArrays = selectedCartItemsList.map(
      (item) => item?.Product?.paymentMethods
    );
    // Check if there's at least one common element across all arrays
    const commonMethods = paymentMethodArrays.reduce((result, methodArray) => {
      return result.filter((method) => methodArray.includes(method));
    }, paymentMethodArrays[0]);
    return commonMethods.length > 0;
  }

  const handleNextPage = () => {
    const result = isAllhaveCommonPaymentMethod();
    if (result) {
      disPatch(setCartPageTabIndex(1));
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Payment methods should be same</h4>
              <p>
                Please makesure at-lease one payment method is same for all
                selected items.
              </p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  const handleNextToPaymentPage = () => {
    if (ShippingAddressList.length > 0) {
      disPatch(setCartPageTabIndex(2));
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Please add your address first in order to proceed.</h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  // All Discount option remove settings
  const handleRemoveAppliedVoucher = () => {
    disPatch(setDiscountAmount(0));
    disPatch(setVoucherContent(""));
    disPatch(setVoucherDiscountAmount(0));
    disPatch(setIsAppliedVoucher(false));


  };

  // All Discount option remove settings
  const handleRemoveAppliedpCoin = () => {
    disPatch(setDiscountAmount(0));
    disPatch(setRedeemedpCoin(0));
    disPatch(setIsAppliedPCoin(false));
    disPatch(setpCoinDiscountAmount(0));
  };

  // All Discount option remove settings
  const handleRemoveAppliedCoupon = () => {
    disPatch(setDiscountAmount(0));
    disPatch(setCouponContent(""));
    disPatch(setIsAppliedCoupon(false));
    disPatch(setCouponDiscountAmount(0));
  };

  const fetchCartList = async () => {
    const response = await cartListService({ filter: "" });
    if (response?.data?.success) {
      disPatch(setCartList(response?.data?.data?.list));
      disPatch(setSelectedCartItemsList(response?.data?.data?.list));
      disPatch(
        setSelectedCartItemsId(
          response?.data?.data?.list?.map((item) => item?.id)
        )
      );
    }
  };
  const handlePayment = async () => {
    ReactGA.event({
      category: "Button click",
      action: "Purchases",
      label: "checkout page",
    });
    if (selectedPaymentMethod == "2" && !user.mobileNo) {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>
                To make the payment from atome kindly add the mobile number from
                edit profile.
              </h4>
              {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    } else {
      if (selectedPaymentMethod) {
        // const isMultipleVendors = _.uniq(selectedCartItemsList.map(item =>
        //   _.find(cartVendorItems, vendorItem =>
        //     _.some(vendorItem.items, i => parseInt(i.productId) === parseInt(item.productId))
        // )?.vendor.id)).length > 1;

        const isMultipleVendors = false;
        if (isMultipleVendors) {
          toast(
            (t) => (
              <div className="toast__block">
                <div className="toast__block__icon">
                  <img src={AlertCircle} alt="" />
                </div>
                <div className="toast__block__content">
                  <h4>You can place order for a single vendor.</h4>
                  {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
                </div>

                <button onClick={() => toast.dismiss(t.id)}>
                  <img src={XClose} alt="" />
                </button>
              </div>
            ),
            {
              style: {
                borderRadius: "10px",
                background: "#D92D20",
                color: "#FFD7D3",
              },
            }
          );
        } else if (requestE_invoice && ((E_invoiceType === "Individual" && !user?.IcFullName && !user?.IcNumber) || (E_invoiceType === "Company" && !user?.UserCompnayInfo))) {
          toast(
            (t) => (
              <div className="toast__block">
                <div className="toast__block__icon">
                  <img src={AlertCircle} alt="" />
                </div>
                <div className="toast__block__content">
                  <h4>Please complete the E-invoice section in your profile.</h4>
                  {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
                </div>

                <button onClick={() => toast.dismiss(t.id)}>
                  <img src={XClose} alt="" />
                </button>
              </div>
            ),
            {
              style: {
                borderRadius: "10px",
                background: "#D92D20",
                color: "#FFD7D3",
              },
            }
          );
        } else {
          const response = await PaymentService({
            cartItemsId: selectedCartItemsList?.map((cartItem) => parseInt(cartItem?.id)),
            addressId: defultShippingID,
            billingAddressId: asDefaultasShippingAddress
              ? defultShippingID
              : defultBillingID,
            ...(voucherContent && { voucherCode: voucherContent }),
            discountOnProduct: totalProductDiscount,
            total: totalAmount,
            earnedPcoin: earningPCoin,
            ...(redeempCoin && { redeemedPcoin: redeempCoin }),
            ...(couponType == "Free Shipping" && { isShippingCoupon: true }),
            weight: weight,
            ...(couponContent && { couponCode: couponContent }),
            paymentMethodId: selectedPaymentMethod,
            ...(deeplink && { referProductIds: deeplink?.productId }),
            ...(deeplink && { referBy: deeplink?.userId }),
            isSelfPickup: isSelfPickup,
            ...(requestE_invoice && E_invoiceType && { requestE_invoice }),
            ...(requestE_invoice && E_invoiceType && { E_invoiceType })
          });
          if (selectedPaymentMethod == "2") {
            if (response?.data?.success) {
              window.location.replace(
                `${response?.data?.data?.atomres?.redirectUrl}`
              );
            } else {
              toast(
                (t) => (
                  <div className="toast__block">
                    <div className="toast__block__icon">
                      <img src={AlertCircle} alt="" />
                    </div>
                    <div className="toast__block__content">
                      <h4>{response?.data?.errorMessage}</h4>
                      {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
                    </div>

                    <button onClick={() => toast.dismiss(t.id)}>
                      <img src={XClose} alt="" />
                    </button>
                  </div>
                ),
                {
                  style: {
                    borderRadius: "10px",
                    background: "#D92D20",
                    color: "#FFD7D3",
                  },
                }
              );
            }
          } else {
            if (response.data.success) {
              window.location.replace(`${response?.data?.data?.ipayUrl}`);
            } else {
              toast(
                (t) => (
                  <div className="toast__block">
                    <div className="toast__block__icon">
                      <img src={AlertCircle} alt="" />
                    </div>
                    <div className="toast__block__content">
                      <h4>{response.data.errorMessage}</h4>
                      {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
                    </div>

                    <button onClick={() => toast.dismiss(t.id)}>
                      <img src={XClose} alt="" />
                    </button>
                  </div>
                ),
                {
                  style: {
                    borderRadius: "10px",
                    background: "#D92D20",
                    color: "#FFD7D3",
                  },
                }
              );
            }
          }
        }
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={AlertCircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Please select any payment method</h4>
                {/* <p>
                You have to select the payment method in order to complete the
                order
              </p> */}
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    }
  };
  const getTotalRedeemablePcoinOnPrice = () => {
    let result = 0;
    selectedCartItemsList.forEach((item) => {
      result +=
        priceCalculator({
          price: item?.ProductVariation?.price,
          discountPrice: item?.ProductVariation?.discount,
        }) *
          perPCoinValue <
          item?.ProductVariation?.redeemPcoinsLimit
          ? parseFloat(
            priceCalculator({
              price: item?.ProductVariation?.price,
              discountPrice: item?.ProductVariation?.discount,
            }) * perPCoinValue
          ) * item?.quantity
          : item?.ProductVariation?.redeemPcoinsLimit * item?.quantity;
    });
    disPatch(setTotalPcoinsBasedOnPrice(result));
  };
  useEffect(() => {
    getTotalRedeemablePcoinOnPrice();
  }, [selectedCartItemsList]);

  const handleSliderUp = () => {
    if (pCoinRedeem > 0)
      document.getElementById("rangeSlider").step = perPCoinValue;
  };
  const handleSliderDown = () => {
    if (pCoinRedeem > 0) document.getElementById("rangeSlider").stepDown();
  };

  return (
    <div className="frame-parent17BillVoucherForm">
      {cartPageTab === 2 && (
        <>
          {!isVoucherApplied ? (
            <>
              <div
                onClick={handleApplyVoucher}
                className="apply-coupon-parentBillVoucherForm"
              >
                <div className="apply-couponBillVoucherForm">
                  Voucher available for this purchase
                </div>
                <div className="frame-parent18BillVoucherForm">
                  <div className="frame-parent19BillVoucherForm">
                    <div className="path-3991-stroke-wrapperBillVoucherForm">
                      <img
                        className="path-3991-strokeBillVoucherForm"
                        alt="coupon-sign"
                        src={coupon_sign}
                      />
                    </div>
                    <div className="enter-no-ofBillVoucherForm">
                      <span>Apply Voucher</span>
                      <img
                        className="aroow-icon1BillVoucherForm"
                        alt="arrow-down"
                        src={uparrow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="apply-coupon-parentBillVoucherForm applyed-coupon-block">
              <div className="enter-no-ofBillVoucherForm">
                <div className="apply-couponBillVoucherForm">
                  Voucher Applied
                </div>
                <img
                  onClick={handleRemoveAppliedVoucher}
                  className="cross-icon1BillVoucherForm"
                  alt="arrow-down"
                  src={CrossIcon}
                />
              </div>
              <div className="frame-parent18BillVoucherForm">
                <div className="frame-parent19BillVoucherForm">
                  <div className="path-3991-stroke-wrapperBillVoucherForm">
                    <img
                      className="path-3991-strokeBillVoucherForm"
                      alt="coupon-sign"
                      src={coupon_sign}
                    />
                  </div>
                  <div className="enter-no-ofBillVoucherForm">
                    <span>{voucherContent}</span>
                    <h4 className="voucher__discount__amount">
                      -RM{voucherDiscountAmount.toFixed(2)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <EarnCoinsForm earningPCoin={earningPCoin} />

      {(cartPageTab === 0 || cartPageTab === 2) && (
        <div className="frame-wrapper3BillVoucherForm">
          {!isPCoinApplied ? (
            <div className="frame-parent20BillVoucherForm">
              <div className="mask-group-parentBillVoucherForm">
                <div className="p-coin-parentBillVoucherForm">
                  <img
                    className="p-coin-icon3BillVoucherForm"
                    alt="pcoin"
                    src={pcoin}
                  />
                  <div className="parent1BillVoucherForm">
                    <div className="div2BillVoucherForm">{PcoinBalance}</div>
                    <div className="available-p-coinsBillVoucherForm">
                      Available p-coins
                    </div>
                  </div>
                </div>
              </div>
              <div className="enter-no-of-p-coins-to-redeem-parentBillVoucherForm">
                <div className="enter-no-ofBillVoucherForm">
                  Enter the{" "}
                  {/* {subTotalPCoinAmount < pCoinBalance
                    ? subTotalPCoinAmount > totalPcoinsBasedOnPrice
                      ? totalPcoinsBasedOnPrice
                      : subTotalPCoinAmount
                    : pCoinBalance > totalPcoinsBasedOnPrice
                    ? totalPcoinsBasedOnPrice
                    : pCoinBalance}{" "} */}
                  {totalPcoinsBasedOnPrice} P-Coins to redeem in multiple of{" "}
                  {perPCoinValue}.
                </div>
                <div className="text-field-parentBillVoucherForm">
                  <form
                    style={{
                      display: "flex",
                      gap: 5,
                    }}
                    onSubmit={handleReedeem}
                  >
                    <div className="text-fieldBillVoucherForm">
                      <div className="label-text4BillVoucherForm">
                        <img
                          className="p-coin-icon4BillVoucherForm"
                          alt="logo"
                          src={pcoin}
                        />
                        {/* <div className="label-text5BillVoucherForm">Enter here</div> */}
                        <input
                          style={{
                            border: "none",
                            background: "transparent",
                          }}
                          type="text"
                          name="pCoinValue"
                          defaultValue={" "}
                          value={pCoinRedeem}
                          placeholder="Enter here"
                          onChange={(e) => setPCoinRedeem(e.target.value)}
                          className="label-text5BillVoucherForm"
                        />

                        <p
                          className="Input__Inner__Btn"
                          onClick={(e) => handleMaxRedeem(e)}
                        >
                          Max
                        </p>
                      </div>
                      <div className="supporting-text1BillVoucherForm">
                        <div className="supporting-text2BillVoucherForm">
                          Supporting text
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="redeem-wrapperBillVoucherForm"
                    >
                      <div className="enter-no-ofBillVoucherForm">Redeem</div>
                    </button>
                  </form>
                </div>
                <div className="slider__Container">
                  <p
                    className="decrement__Btn"
                    onClick={() => {
                      pCoinRedeem > 0 &&
                        setPCoinRedeem(
                          Number(pCoinRedeem) - Number(perPCoinValue)
                        );

                      handleSliderDown();
                    }}
                  >
                    <FaMinus />
                  </p>
                  <input
                    type="range"
                    id="rangeSlider"
                    name="pCoinValue"
                    min={0}
                    max={9999}
                    value={pCoinRedeem}
                    onChange={(e) => setPCoinRedeem(e.target.value)}
                  />
                  <p
                    className="increment__Btn"
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => {
                      setShowTooltip(true);
                      pCoinRedeem < 9900 &&
                        setPCoinRedeem(
                          Number(pCoinRedeem) + Number(perPCoinValue)
                        );
                      handleSliderUp();
                    }}
                  >
                    <FaPlus />
                    {/* {showTooltip && pCoinBalance < pCoinRedeem && (
                      <p className="ToolTip">
                        You exceeded the P-Coins Balance!
                      </p>
                    )} */}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="discount__applied__block">
              <div className="discount__title__block">
                <div className="discount__title">
                  <img
                    className="p-coin-icon3BillVoucherForm"
                    alt="pcoin"
                    src={pcoin}
                  />
                  <h3>Redeem P-Coins</h3>
                </div>
                <h3
                  onClick={handleRemoveAppliedpCoin}
                  className="discount__remove"
                >
                  Remove
                </h3>
              </div>
              <div className="discount__body__block">
                <div className="discount__body__content__block">
                  <img
                    className="p-coin-icon3BillVoucherForm"
                    alt="pcoin"
                    src={RedCross}
                  />
                  <h3>{redeempCoin} P-Coins</h3>
                </div>
                <div className="discount__body__amount__block">
                  <h3>RM {discountpCoinAmount.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {cartPageTab === 2 && (
        <div className="frame-parent21BillVoucherForm">
          {!isCouponApplied ? (
            <>
              <div className="frame-parent22BillVoucherForm">
                <div className="frame-parent23BillVoucherForm">
                  <img
                    className="frame-iconBillVoucherForm"
                    alt="coupon-sign"
                    src={coupon_sign}
                  />
                  <div className="enter-no-ofBillVoucherForm">Apply Coupon</div>
                </div>
                <img
                  className="chevron-up-iconBillVoucherForm"
                  alt="up-arrow"
                  src={uparrow}
                />
              </div>
              <div className="text-field1BillVoucherForm">
                <div className="label-text-parentBillVoucherForm">
                  <form
                    onSubmit={handleApplyCoupon}
                    style={{
                      display: "flex",
                      gap: 15,
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      className="label-text5BillVoucherForm"
                      placeholder="Enter coupon code"
                      type="text"
                      name="couponCode"
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                    />

                    <button
                      type="submit"
                      className="label-text7BillVoucherForm"
                    >
                      Apply
                    </button>
                  </form>
                </div>
                <div className="supporting-text1BillVoucherForm">
                  <div className="supporting-text2BillVoucherForm">
                    Supporting text
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="discount__applied__block">
              <div className="discount__title__block">
                <div className="discount__title">
                  <img
                    className="p-coin-icon3BillVoucherForm"
                    alt="pcoin"
                    src={coupon_sign}
                  />
                  <h3>{`‘${couponContent}’`} applied</h3>
                </div>
                <h3
                  onClick={handleRemoveAppliedCoupon}
                  className="discount__remove"
                >
                  Remove
                </h3>
              </div>
              <div className="discount__body__block">
                <div className="discount__body__content__block">
                  <img
                    className="p-coin-icon3BillVoucherForm"
                    alt="pcoin"
                    src={RedCross}
                  />
                  <p className="discount__body__content__text--two">
                    <span>RM {couponDiscountAmount.toFixed(2)}</span> Coupon
                    savings
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="bill-details-groupBillVoucherForm">
        <div className="bill-details1BillVoucherForm">Bill Details</div>
        <div className="frame-parent24BillVoucherForm">
          <div className="label-text-parentBillVoucherForm">
            <div className="label-text5BillVoucherForm">Sub Total</div>
            <div className="sku-hrw-not-000160115BillVoucherForm">
              RM {totalAmount.toFixed(2)}
            </div>
          </div>
          <div className="label-text-parentBillVoucherForm">
            <div className="label-text5BillVoucherForm">
              Discount on product
            </div>
            <div className="sku-hrw-not-000160117BillVoucherForm">
              -RM {(totalAmount - subTotalAmount).toFixed(2)}
            </div>
          </div>

          {shipingCost > 0 && cartPageTab !== 0 && (
            <div className="label-text-parentBillVoucherForm">
              <div className="label-text5BillVoucherForm">Shipping</div>

              <div className="sku-hrw-not-000160115BillVoucherForm">
                RM {isSelfPickup ? "0.00" : shipingCost.toFixed(2)}
              </div>
            </div>
          )}
          {isCouponApplied && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p>{isCouponApplied && "Coupon"}</p>
              <p style={{ color: "green" }}>
                {" "}
                {isCouponApplied && "-RM " + couponDiscountAmount.toFixed(2)}
              </p>
            </div>
          )}
          {isPCoinApplied && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p>{isPCoinApplied && "P-Coin"}</p>
              <p className="discountType__Text" style={{ color: "green" }}>
                {" "}
                {isPCoinApplied && "-RM " + discountpCoinAmount.toFixed(2)}
              </p>
            </div>
          )}
          {isVoucherApplied && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p>{isVoucherApplied && "Voucher"}</p>
              <p style={{ color: "green" }}>
                {" "}
                {isVoucherApplied && "-RM " + voucherDiscountAmount.toFixed(2)}
              </p>
            </div>
          )}

          {/* <div className="label-text5BillVoucherForm">Voucher</div>
            <div className="sku-hrw-not-000160121BillVoucherForm">
              {isVoucherApplied ? "Apply" : "-"}
            </div> */}
          {/* <div className="label-text5BillVoucherForm">P-Coin Redemption</div>
            <div className="sku-hrw-not-000160115BillVoucherForm">
              {isPCoinApplied ? "Apply" : "-"}
            </div> */}
          <div className="frame-child7BillVoucherForm" />
          <div className="sku-hrw-not-0001601-parent11BillVoucherForm">
            <div className="enter-no-ofBillVoucherForm">Total</div>
            <div className="sku-hrw-not-000160127BillVoucherForm">
              RM{" "}
              {Math.abs(
                subTotalAmount +
                Number(
                  cartPageTab === 0 ? 0 : isSelfPickup ? 0 : shipingCost
                ) -
                discountAmount
              ).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      {cartPageTab === 0 && (
        <>
          {selectedCartItemsList?.length < 1 ? (
            <button
              className="cartBillVoucherForm__btn cartBillVoucherForm__btn--disabled"
              disabled
            >
              Place Order ({selectedCartItemsList?.length} item)
            </button>
          ) : (
            <button
              onClick={handleNextPage}
              className="cartBillVoucherForm__btn"
            >
              Place Order ({selectedCartItemsList?.length} item)
            </button>
          )}
        </>
      )}
      {cartPageTab === 1 && (
        <>
          {/* {!defultShippingID || !defultBillingID ? (
            <button
              className="cartBillVoucherForm__btn cartBillVoucherForm__btn--disabled"
              disabled
            >
              Continue
            </button>
          ) : (
          )} */}
          <button
            onClick={handleNextToPaymentPage}
            className="cartBillVoucherForm__btn"
          >
            Continue
          </button>
        </>
      )}
      {cartPageTab === 2 && (
        <>
          {selectedCartItemsList?.length < 1 ? (
            <button
              className="cartBillVoucherForm__btn cartBillVoucherForm__btn--disabled"
              disabled
            >
              Pay Now
            </button>
          ) : (
            <button
              onClick={handlePayment}
              className="cartBillVoucherForm__btn"
            >
              Pay Now
            </button>
          )}
        </>
      )}
      {isOpenVoucherModal && (
        <VouchersModal
          voucherModaltoggle={voucherModaltoggle}
          isOpenVoucherModal={isOpenVoucherModal}
        />
      )}
      {isOpenPCoinReddemModal && (
        <PCoinReddemModal
          PCoinReddemModaltoggle={PCoinReddemModaltoggle}
          isOpenPCoinReddemModal={isOpenPCoinReddemModal}
          pCoinModalData={pCoinModalData}
        />
      )}
      <Toaster position="top-right" />
    </div>
  );
});

export default BillVoucherForm;
