import React from "react";
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";
import "../../assets/scss/base/pages/DeliveryPolicy/DeliveryPolicy.scss";
import { Helmet } from "react-helmet";

const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const DeliveryPolicyPage = () => {
  return (
    <div className="Delivery_Policy">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Delivery Policy</title>
        <meta
          name="description"
          content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
        />
      </Helmet>
      <p>
        <img
          src={`${imageCDN}/public/content-images/3-Hr-Delivery-KV-Web-Sub-Banner.jpeg`}
          alt=""
          width="100%"
        />
      </p>
      <p>&nbsp;</p>
      <h2>3 Hour Delivery Term &amp; Conditions :</h2>
      <p>&nbsp;</p>
      <p>
        1 Upon placing your order, the order is expected to reach the customer’s
        location within 3 hours, depending on the customer’s location and the
        availability of the goods.
      </p>
      <p>2 Delivery service available on weekdays, 9 ~5pm only.</p>
      <p>
        3 Delivery available on selected areas including Ampang, Bangsar,
        Cheras, Damansara, KL City Centre, PJ, Puchong Seputeh. Check details
        below.
      </p>
      <p>
        3 Delivery charges for locations in West Malaysia are dependent on the
        weight of the item
      </p>
      <p>
        4 <strong>Self-pick @ Wisma Pineapple</strong> is also available.
        Customer are to be advised to call,{" "}
        <a href="m.me/PineappleMall" target="_blank" rel="noopener">
          Facebook Messenger
        </a>{" "}
        or Whatsapp our hotline number @ <strong>019-327 8400</strong> first to
        know when they are coming to collect their items.
      </p>
      <p>
        5 Currently delivery in West Malaysia is only allowed in Pineapple.my.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>Please feel free to contact us via any channel below :</p>
      <p>
        1 Via our Live Chat service in{" "}
        <a style={{ color: "#0000ff" }} href="http://www.pineapple.my">
          www.pineapple.my
        </a>{" "}
        ( Weekdays from 9am till 5pm )
      </p>
      <p>2 Via our mobile hotline at 019-3278400 ( Call, SMS or What’s App )</p>
      <p>
        3 Via our email at{" "}
        <a
          style={{ color: "#0000ff" }}
          href="mailto:http://marketing@pineapple.my"
        >
          marketing@pineapple.my
        </a>
      </p>
      <p>
        <a
          style={{ color: "#0000ff" }}
          href="mailto:http://marketing@pineapple.my"
        >
          {" "}
          <br />
        </a>
      </p>
      <p>&nbsp;</p>
      <hr />
      <h1>3 Hours Delivery Cover Area</h1>
      <p>&nbsp;</p>
      <table class="delivery-table-container" border="0">
        <tbody>
          <tr>
            <td>
              <img
                src={`${imageCDN}/public/content-images/3hr-ampang-01.jpeg`}
                alt=""
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-ampang-02.jpeg`}
                  alt=""
                  width="100%"
                />
              </p>
              <p>Ampang postcode: 54200, 55000, 55100, 55300, 68000</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-bangsar.jpeg`}
                  alt=""
                  width="100%"
                />
              </p>
              <p>Bangsar postcode: 59000, 59100, 59200</p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={`${imageCDN}/public/content-images/3hr-cheras-01.jpeg`}
                alt=""
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-cheras-02.jpeg`}
                  alt=""
                />
              </p>
              <p>Cheras postcode: 43200, 55200, 56000, 56100</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-damansara.jpeg`}
                  alt=""
                />
              </p>
              <p>Damansara postcode: 50480, 50490</p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={`${imageCDN}/public/content-images/3hr-kl-01.jpeg`}
                alt=""
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-kl-02.jpeg`}
                  alt=""
                />
              </p>
              <p>
                KL City Centre postcode: 50050, 50088, 50100, 50150, 50200,
                50250, 50300, 50350, 50450, 50460, 50470, 50480, 50603
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-pj.jpeg`}
                  alt=""
                />
              </p>
              <p>PJ postcode: 46000</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-puchong.jpeg`}
                  alt=""
                />
              </p>
              <p>Puchong&nbsp;postcode: 47100, 47180</p>
            </td>
          </tr>
          <tr>
            <td>
              <img
                src={`${imageCDN}/public/content-images/3hr-seputeh-01.jpeg`}
                alt=""
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <img
                  src={`${imageCDN}/public/content-images/3hr-seputeh-02.jpeg`}
                  alt=""
                />
              </p>
              <p>Seputeh&nbsp;postcode: 57000, 57100, 58000, 58100, 58200</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withLayout(DeliveryPolicyPage, {
  isProductPage: false,
  isNestedPageName: false,
});
