import { memo, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// async-Select
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
// Icons
import { FiShoppingBag } from "react-icons/fi";
import { GrFavorite } from "react-icons/gr";
import { IoMdLogOut } from "react-icons/io";
import { BsPerson, BsBoxSeam, BsBookmarkPlus, BsLock } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";

import { useNavigate, Link } from "react-router-dom";
import { FiBell } from "react-icons/fi";

// API-Service
import {
  getPCoinBalanceService,
  getUserProfile,
} from "../../service/user.service";
import { logout } from "../../service/auth.service";
import getProductList from "../../service/catalogue.service";
// Utility-Settings
import { removeUserSession, getUser } from "../../utils/AuthService";
// Redux-Settings
import { isLoggedIn, setLogout } from "../../redux/authSlice";
import { selectCartList } from "../../redux/cartSlice";
import {
  setIsLoginPopup,
  selectIsLoginPopup,
  setCategoryDropdownValue,
  selectIsSmallScreen,
} from "../../redux/utilitySlice";
import {
  setCartList,
  setSelectedCartItemsList,
  setSelectedCartItemsId,
} from "../../redux/cartSlice";
// Images
import pcoinLogo from "../../assets/images/header/siteLogo.svg";
import { bag } from "../../assets/images/homepage";
import { pcoin } from "../../assets/images/homepage";
import SearchIcon from "../../assets/images/homepage/search-icon.png";
import cartIcon from "../../assets/images/header/shoppingBag.svg";
import wishListIcon from "../../assets/images/header/Heart.svg";
import notificationIcon from "../../assets/images/header/Notifications.svg";

// Component
import LoginMessageModal from "../modal/LoginMessageModal";

// Styles
import "../../assets/scss/base/pages/account/MainHeader.scss";
import "../../assets/scss/base/pages/global.scss";
import {
  selectUserDetails,
  setPCoinBalance,
  setUserDetail,
} from "../../redux/userSlice";
import {
  setLoginModalToggle,
  setRegisterModalToggle,
} from "../../redux/modalsSlice";
import Notification from "../notification/Notification";
import { getNotificationService } from "../../service/notification.service";
import {
  setNotificationList,
  setPages,
  setTotalNotification,
} from "../../redux/notificationSlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const Header = memo(() => {
  const dispatch = useDispatch();
  const isLoginMessageModal = useSelector(selectIsLoginPopup);
  const isLoggdin = useSelector(isLoggedIn);
  const cartItems = useSelector(selectCartList);
  const isSmallScreen = useSelector(selectIsSmallScreen);

  // Dropdown Settings
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);
  const [notificationLimit, setNotificationLimit] = useState();

  const [searchParam, setSearchParam] = useState();
  const [loading, setLoading] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate();
  const userDetail = useSelector(selectUserDetails);
  useEffect(() => {
    const fetchUserBalance = async () => {
      const userBalance = await getPCoinBalanceService("");
      dispatch(setPCoinBalance(userBalance?.data?.data));
    };

    fetchUserBalance();
  }, [isLoggdin]);

  useEffect(() => {
    const fetchUserNotifications = async () => {
      setLoading(true);
      const result = await getNotificationService(notificationLimit);
      if (result?.data?.success) {
        dispatch(setNotificationList(result?.data?.data.list));
        dispatch(setPages(result?.data?.data?.pages));
        dispatch(setTotalNotification(result?.data?.data?.count));
      }
      setLoading(false);
    };
    fetchUserNotifications();
  }, [notificationLimit, isLoggdin]);
  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await getUserProfile();
      if (user?.data?.success) {
        dispatch(setUserDetail(user?.data?.data));
      }
    };
    fetchUserDetails();
  }, [isLoggdin]);
  /**
   * @function handleLogOutClick
   * @param {}
   * @description This function is used to logout the user
   */

  const handleLogOutClick = async (e) => {
    e.preventDefault();
    const response = await logout();
    if (response?.data?.success) {
      dispatch(setLogout(false));
      removeUserSession();
      dispatch(setUserDetail(" "));
      dispatch(setCartList([]));
      dispatch(setSelectedCartItemsList([]));
      dispatch(setSelectedCartItemsId([]));
      dispatch(setNotificationList([]));
      dispatch(setPages(0));
      dispatch(setTotalNotification(0));
      navigate("/");
    }
  };

  // async-Select for product
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <img
        src={SearchIcon}
        alt=""
        style={{
          padding: "0 8px",
        }}
      />{" "}
      {children}
    </components.Control>
  );
  const PlaceHolder = ({ children, ...props }) => (
    <components.Placeholder {...props}>
      <span
        style={{
          color: "#667085",
          fontSize: 16,
        }}
      >
        Search
      </span>
    </components.Placeholder>
  );

  const [productSelectedValue, setProductSelectedValue] = useState(null);
  const getImage = (item) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            cursor: "Pointer",
            alignItems: "start",
            gap: "0.6rem",
          }}
          className="hover__State"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/product/${item?.slug}`);
          }}
        >
          {item?.thumbnail && (
            <img
              src={`${imageCDN}/${item.thumbnail}`}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
          )}
          <p> {item.name}</p>
        </div>
      </>
    );
  };
  const productLoadOptions = async (searchQuery, loadedOptions, additional) => {
    const page = additional?.page ?? 1;

    if (!searchQuery) {
      return {
        options: [],
        hasMore: false,
      };
    }
    try {
      const response = await getProductList({
        page,
        search: searchQuery || "",
        categoryId: "",
        brandId: "",
        subCategoryId: "",
        subCategoryTypeId: "",
        isOnFlashSale: "",
      });
      if (response?.data?.success) {
        const result = response?.data?.data?.list?.map((item) => ({
          value: String(item.id),
          // eslint-disable-next-line jsx-a11y/alt-text
          label: getImage(item),
          ...item,
        }));
        return {
          options: result,
          hasMore: Math.ceil(response?.data?.data?.count / 10) > page,
          additional: {
            page: page + 1,
          },
        };
      }
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  // useEffect(() => {
  //   window.location.reload(true);
  // }, [productSelectedValue]);
  // const productOnChange = (newValue, e) => {
  //   if (newValue) {
  //     setProductSelectedValue(newValue);
  //     // dispatch(
  //     //   setCategoryDropdownValue({
  //     //     state: {
  //     //       id: newValue?.categoryId,
  //     //       brandId: newValue?.brandId,
  //     //       subCatId: newValue?.SubCategory?.id,
  //     //       categoryName: newValue?.metadata?.category,
  //     //       subCatagoryName: newValue?.metadata?.subCategory,
  //     //       brandName: newValue?.metadata?.brand,
  //     //     },`
  //     //   })
  //     // );
  //     if (e.keyCode == 13) {
  //       navigate(`/catalogue`);
  //     } else {
  //       if (newValue?.slug) {
  //         navigate(`/product/${newValue?.slug}`);
  //       } else {
  //         navigate(`/catalogue`);
  //       }
  //     }
  //   }
  // };
  // function fun() {
  //
  // }
  function handleClick() {
    document.addEventListener("click", (e) => {
      if (e.target.id === "Notify__Bell") {
        setNotificationDropdownOpen(!notificationDropdownOpen);
        e.stopPropagation();
      } else {
        setNotificationDropdownOpen(false);
      }
    });
  }
  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      // productLoadOptions("enterPressed");
      const searchQuery = e.target.value;

      const slug = () => {
        return searchQuery.trim().split(" ").join("-");
      };
      navigate(`/catalogue?search=${slug()}`);
    }
  };
  return (
    <div className="header__container" style={{ padding: "1rem  30px" }}>
      <div className="header__container__left___block">
        <Link to="/">
          <img
            className="header__container__left___block__logo"
            alt="logo"
            src={pcoinLogo}
          />
        </Link>
        <div
          className="header__container__left___block__search--block"
          style={{ zIndex: "10" }}
        >
          <AsyncPaginate
            loadOptions={productLoadOptions}
            isSearchable
            isClearable
            onKeyDown={(e) => handleSearch(e)}
            // onClick={(e) => productOnChange(e)}
            placeholder={"Search for Products, Brands and More"}
            components={{ Control, PlaceHolder }}
            additional={{
              page: 1,
            }}
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: "16px",
                color: "#667085",
                fontWeight: 400,
                // fontFamily:
                //   "inter_regular,fallback-inter_regular,Arial,sans-serif",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "8px",
                borderColor: state.isFocused ? "#D71513" : "#D0D5DD",
                zIndex: "1000",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                overflow: "visible",
              }),

              option: (baseStyles, state) => ({
                ...baseStyles,
                padding: "10px 20px",
                color: state.isSelected ? "#425EBE" : "#000",
                fontSize: "14px",
                backgroundColor: state.isSelected ? "#FFF3F3" : "white",
                borderBottom: "1px solid #EDEDED",
                zIndex: "1000",
              }),
            }}
          />
        </div>
      </div>
      <div className="header__container__right___block">
        <>
          {isSmallScreen && !isLoggdin ? null : (
            <>
              <div className="header-icon">
                <div className="cart-bag-block">
                  {cartItems?.length > 0 ? (
                    <span className="cart-bag-badge">{cartItems?.length}</span>
                  ) : null}
                  <img
                    src={cartIcon}
                    alt="Cart__Icon"
                    className="Header__Icon"
                    onClick={() => {
                      if (isLoggdin) {
                        navigate("/cart-page");
                      } else {
                        // dispatch(setIsLoginPopup(true))
                        dispatch(setLoginModalToggle(true));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="header-icon">
                <img
                  src={wishListIcon}
                  alt="wishListIcon"
                  className="Header__Icon"
                  onClick={() => {
                    if (isLoggdin) {
                      navigate("/wishlist-page");
                    } else {
                      // dispatch(setIsLoginPopup(true))
                      dispatch(setLoginModalToggle(true));
                    }
                  }}
                />
              </div>
              <div
                className={
                  notificationDropdownOpen
                    ? "header-icon-active"
                    : "header-icon-notification"
                }
              >
                <img
                  src={notificationIcon}
                  alt="notificationIcon"
                  id="Notify__Bell"
                  className="Header__Icon"
                  onClick={() => {
                    if (isLoggdin) {
                      // setNotificationDropdownOpen(!notificationDropdownOpen);
                      handleClick();
                    } else {
                      // dispatch(setIsLoginPopup(true))
                      dispatch(setLoginModalToggle(true));
                    }
                  }}
                />
                {notificationDropdownOpen && (
                  <Notification
                    loading={loading}
                    setNotificationLimit={setNotificationLimit}
                    notificationDropdownOpen={notificationDropdownOpen}
                    setNotificationDropdownOpen={setNotificationDropdownOpen}
                  />
                )}
              </div>
            </>
          )}
          {userDetail?.password && (
            <Dropdown
              className="profileDropdown"
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <DropdownToggle className="profileDropdown__toggle__btn">
                {userDetail?.file ? (
                  <img
                    className="header-profile-image"
                    alt="profile"
                    src={`${imageCDN}/${userDetail?.file}`}
                  />
                ) : (
                  <div className="header-profile-image default-profile-thumb">
                    <h3>
                      {userDetail?.username ? userDetail?.username[0] : ""}
                    </h3>
                  </div>
                )}
              </DropdownToggle>
              <DropdownMenu style={{ zIndex: "1001" }}>
                <DropdownItem
                  className="profile-header"
                  onClick={() => navigate("/account/overview")}
                  style={{ background: "none" }}
                >
                  {userDetail?.file ? (
                    <img
                      alt="profile_image"
                      className="profile-header-img"
                      src={`${imageCDN}/${userDetail?.file}`}
                    />
                  ) : (
                    <div className="profile-header-img default-profile-thumb">
                      <h3>
                        {userDetail?.username ? userDetail?.username[0] : ""}
                      </h3>
                    </div>
                  )}
                  <div className="profile-name-email">
                    <p className="profile-name">{userDetail?.username}</p>
                    <p className="profile-email" style={{ color: "#000000" }}>
                      {userDetail?.email}
                    </p>
                  </div>
                </DropdownItem>
                <DropdownItem
                  className="profile-link"
                  onClick={() => navigate("/account/profile")}
                >
                  <BsPerson />
                  <span>View profile</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link"
                  onClick={() => navigate("/account/e-invoice")}
                >
                  <BsBookmarkPlus />
                  <span>E-Invoice</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link"
                  onClick={() => navigate("/account/orders")}
                >
                  <BsBoxSeam />
                  <span>Orders</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link border__bottom"
                  onClick={() => {
                    navigate("/wishlist-page");
                  }}
                >
                  <GrFavorite />
                  <span>Wishlist</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link"
                  onClick={() => {
                    navigate("/account/P-Coin");
                  }}
                >
                  <img alt="pcoin_logo" src={pcoin} />
                  <span>P-Coins</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link border__bottom"
                  onClick={() => navigate("/account/dailyLoginBonus")}
                >
                  <img alt="pcoin_logo" src={pcoin} />
                  <span>Daily Login Bonus</span>
                </DropdownItem>
                <DropdownItem
                  className="profile-link"
                  onClick={() => navigate("/account/addressBook")}
                >
                  <BsBookmarkPlus />
                  <span>Address Book</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate("/account/changepassword");
                  }}
                  className="profile-link border__bottom"
                >
                  <BsLock />
                  <span>Change Password</span>
                </DropdownItem>

                <DropdownItem
                  onClick={handleLogOutClick}
                  className="profile-link profile-link__v2"
                >
                  <IoMdLogOut />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </>
        {!userDetail?.password && (
          <div className="header-btn-group">
            <button
              className="header-sign-in-btn"
              onClick={() => dispatch(setLoginModalToggle(true))}
            >
              Sign In
            </button>
            <button
              className="header-sign-up-btn"
              onClick={() => navigate("/register")}
            >
              Register
            </button>
          </div>
        )}
      </div>
      {isLoginMessageModal && <LoginMessageModal />}
    </div>
  );
});

export default Header;
