import React from "react";

// images
import PCoinLarge from "../../assets/P-CoinLargeIcon.png";
import AppStore from "../../assets/app-store.png";
import PlayStore from "../../assets/play-store.png";

const Share = () => {
  return (
    <div className="container-fluid px-0 min-vh-100">
      <div className="py-5 bg-danger d-flex flex-column justify-content-center align-items-center">
        <img
          className="img-fluid"
          src={PCoinLarge}
          alt="pcoin"
        />
        <span className="mt-4 text-light h2">P-Coin</span>
        <span className="mt-2 text-light h3">Get Our Free App</span>
      </div>
      <div className="mt-4 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <img
          className="img-fluid me-sm-2"
          src={PlayStore}
          alt="pcoin"
          style={{ maxWidth: "165px" }}
        />
        <img
          className="img-fluid mt-3 mt-sm-0"
          src={AppStore}
          alt="pcoin"
          style={{ maxWidth: "165px" }}
        />
      </div>
    </div>
  );
};

export default Share;
