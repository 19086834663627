import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import toast, { Toaster } from "react-hot-toast";
import AlertCircle from "../../../assets/images/homepage/alert-circle.svg";
import CheckCircleBrokenIcon from "../../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../../assets/images/homepage/x-close.svg";
// API-Service
import {
  updateAddressService,
  addressListService,
} from "../../../service/address.service";

// Redux-Settings
import {
  setShippingAddressList,
  setBillingAddressList,
  setDefaultShippingAddressId,
  setDefaultBillingAddressId,
} from "../../../redux/addressSlice";

function UpdateAddressModal({
  toggleUpdate,
  formValue,
  addressType,
  setUpdateAddressClicked,
}) {
  const dispatch = useDispatch();

  // Form Data
  const [formData, setFormData] = useState({});
  const [phoneInputError, setPhoneInputError] = useState();
  const [phoneLengthError, setPhoneLengthError] = useState();
  const [zipCodeLengthError, setZipCodeLengthError] = useState(false);

  const [zipcodeError, setZipcodeError] = useState();
  const [showError, setShowError] = useState(false);
  const [isTinNumber, setTinNumber] = useState(false);
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const inputValue = value;

    if (inputValue.length >= 9 && inputValue.length <= 11) {
      setFormData({
        ...formData,
        [name]: value,
      });
      setPhoneInputError("");
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    // if (inputValue.length < 1) {
    //   setPhoneInputError("Phone number cannot be empty");
    // }
  };
  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;

    const inputValue = value;
    setPhoneLengthError(false);

    if (inputValue.length >= 9 && inputValue.length <= 11) {
      setFormData({
        ...formData,
        [name]: value,
      });
      setPhoneInputError("");
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setPhoneLengthError(true);
      setPhoneInputError("Phone number must be between 9 and 11 digits.");
    }
    if (inputValue.length < 1) {
      setPhoneInputError("Phone number cannot be empty");
    }
  };
  const handleZipCodeChange = (e) => {
    const { name, value } = e.target;

    const inputValue = value;
    setZipCodeLengthError(false);

    if (inputValue.length == 5) {
      setFormData({
        ...formData,
        [name]: value,
      });
      setZipcodeError("");
    } else {
      setZipCodeLengthError(true);
      setZipcodeError("Zipcode must be of 5 digits.");
    }
    if (inputValue.length < 1) {
      setZipcodeError("Zipcode number cannot be empty");
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  // Handle Country Select
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (value) => {
    setValue(value);
  };

  useEffect(() => {
    setFormData(formValue);
    setValue({
      value: formValue?.country,
      label: formValue?.country,
    });
  }, [formValue]);

  const fetchShippingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Shipping",
    });
    if (response?.data?.success) {
      dispatch(setShippingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultShippingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const fetchBillingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Billing",
    });
    if (response?.data?.success) {
      dispatch(setBillingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultBillingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const handleUpdateItem = async (e) => {
    e.preventDefault();

    if (!formData?.company && formData?.tin) {
      formData.tin = "";
    }

    if (formData?.company && !formData?.tin) {
      setTinNumber(true);
    } else {
      if (zipCodeLengthError || phoneLengthError) {
        setShowError(true);
      } else {
        const {
          receiptient,
          mobile,
          address,
          city,
          tin,
          state,
          zipCode,
          company,
          isDefault,
        } = formData;
        const response = await updateAddressService({
          receiptient,
          mobile,
          address,
          city,
          tin,
          state,
          zipCode,
          company,
          isDefault,
          type: addressType,
          country: "Malaysia",
          id: formData?.id,
        });
        if (response?.data?.success) {
          if (addressType === "Shipping") {
            setUpdateAddressClicked(true);

            fetchShippingAddressList();
          }
          if (addressType === "Billing") {
            fetchBillingAddressList();
          }
          toggleUpdate();
        } else {
          toast(
            (t) => (
              <div className="toast__block">
                <div className="toast__block__icon">
                  <img src={AlertCircle} alt="" />
                </div>
                <div className="toast__block__content">
                  <h4>{response?.data?.errorMessage}</h4>
                  {/* <p>Please put valid data.</p> */}
                </div>

                <button onClick={() => toast.dismiss(t.id)}>
                  <img src={XClose} alt="" />
                </button>
              </div>
            ),
            {
              style: {
                borderRadius: "10px",
                background: "#D92D20",
                color: "#FFD7D3",
              },
            }
          );
        }
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleUpdateItem}>
        <FormGroup>
          <Label for="receiptient">Full Name*</Label>
          <Input
            type="text"
            name="receiptient"
            id="receiptient"
            placeholder="Enter your name"
            value={formData.receiptient}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="mobile">Phone Number</Label>
          <Input
            type="text"
            name="mobile"
            id="mobile"
            placeholder="Enter your phone number"
            value={formData.mobile}
            onChange={handlePhoneInputChange}
          />
          {phoneLengthError && (
            <span style={{ color: "#D71513" }}>{phoneInputError}</span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="company">Company</Label>
          <Input
            type="text"
            name="company"
            id="company"
            placeholder="Enter company name here"
            value={formData.company}
            onChange={handleInputChange}
          />
        </FormGroup>

        {formData?.company && (
          <FormGroup>
            <Label for="company">TIN</Label>
            <Input
              type="text"
              name="tin"
              id="tin"
              placeholder="Enter TIN name here"
              value={formData.tin}
              onChange={handleInputChange}
            />
            {isTinNumber && (
              <span className="address_error">Enter Tin Number here</span>
            )}
          </FormGroup>
        )}
        <FormGroup>
          <Label for="countrySelect">Country</Label>
          {/* <Select
            options={options}
            value={value}
            onChange={changeHandler}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "none",
                borderColor: state.isFocused ? "#D71513" : "#dee2e6",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                overflow: "visible",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isSelected ? "#FFF" : "#000",
                backgroundColor: state.isSelected ? "#D71513" : "white",
              }),
            }}
          /> */}
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Enter your street address"
            value="Malaysia"
          />
        </FormGroup>
        <FormGroup>
          <Label for="address">Street Address</Label>
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Enter your street address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="city">City</Label>
          <Input
            type="text"
            name="city"
            id="city"
            placeholder="Enter city here"
            value={formData.city}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="state">State</Label>
          <Input
            type="text"
            name="state"
            id="state"
            placeholder="Enter state here"
            value={formData.state}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="zipCode">Zip/Postal Code</Label>
          <Input
            type="text"
            name="zipCode"
            id="zipCode"
            placeholder="Enter postal code here"
            defaultValue={formData?.zipCode}
            onChange={handleZipCodeChange}
          />
          {zipCodeLengthError && (
            <span style={{ color: "#D71513" }}>{zipcodeError}</span>
          )}
        </FormGroup>

        <FormGroup check>
          <Input
            name="isDefault"
            id="isDefault"
            type="checkbox"
            checked={formData.isDefault}
            onChange={handleCheckboxChange}
          />
          <Label for="isDefault" check>
            Make this my default address
          </Label>
        </FormGroup>
        <FormGroup>
          <button
            type="submit"
            className="primary__btn"
            onClick={handleUpdateItem}
          >
            Update Address
          </button>
        </FormGroup>
      </Form>
    </div>
  );
}

export default UpdateAddressModal;
