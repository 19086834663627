import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//styles
import "../../assets/scss/base/pages/account/Overview/AccountOverviewCard.scss";
//images
import PCoinIcon from "../../assets/images/account/DailyLoginBonus/P-CoinLargeIcon.png";
import chevronRight from "../../assets/images/account/Overview/chevronRight.svg";
import coin from "../../assets/images/account/Overview/coin.svg";
import { selectPcoinBalance, selectUserDetails } from "../../redux/userSlice";

const AccountOverviewCard = () => {
  const navigate = useNavigate();
  const userDetail = useSelector(selectUserDetails);
  const PcoinBalance = useSelector(selectPcoinBalance);

  const getLastClaimedDay = () => {
    let day;
    userDetail?.loginBonus?.forEach((ClaimDetail) => {
      if (ClaimDetail?.status === "Claimed") {
        day = ClaimDetail?.day;
      }
    });
    return day;
  };
  return (
    <div
      onClick={() => navigate("/account/dailyLoginBonus")}
      style={{ cursor: "pointer" }}
    >
      <div className="AccountOverview__Top__Container">
        <div className="AccountOverview__Card__Container">
          <div className="AccountOverview__Available__Card">
            <div>
              <img
                src={PCoinIcon}
                alt="P-Coin"
                className="AccountOverview__Available__Icon"
              />
            </div>
            <div>
              <h3 className="AccountOverview__Available">{PcoinBalance}</h3>
              <p className="AccountOverview__Available__Text">
                Available coins
              </p>
            </div>
          </div>
          <div className="AccountOverview__Bottom__Container">
            <div className="AccountOverview__Bottom__Inner__Container">
              <h6>Daily Login Bonus</h6>

              <h6 className="Bottom__Inner__Right__Container__Text">
                Day {getLastClaimedDay()}
                <img src={chevronRight} alt="icon" />
              </h6>
            </div>
            {/* Daily Login Bonus Progress bar */}
            <div className="Bottom__Main__Container">
              {userDetail?.loginBonus?.map((item, index) => {
                return (
                  <div className="Bottom__Container__Progress__Bar" key={index}>
                    <div
                      className={
                        item?.status === "Claimed"
                          ? "Progess__Bar__Color__Filled__Circle"
                          : "Progess__Bar__Not__Filled__Circle"
                      }
                    >
                      {item?.status === "Claimed" && (
                        <img src={coin} alt="coin" />
                      )}
                    </div>

                    <hr
                      className={
                        index === 6
                          ? "Not__show"
                          : item?.status === "Claimed"
                            ? "ProgessBar__Line__Colored"
                            : "Progess__Bar__Line"
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountOverviewCard;
