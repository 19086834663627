import axios from "axios";
import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function getUserProfile
 * @param
 * @description This function is used to get the user data object
 */

export const getUserProfile = async () => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.get(`${url}/common/u/profile`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function updateUserProfile
 * @param {username, mobileNo, email, gender, dob}
 * @description This function is used to update the user data
 */

export const updateUserProfile = async ({
  username,
  mobileNo,
  email,
  gender,
  dob,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.post(
      `${url}/common/u/update-profile`,
      {
        username,
        mobileNo,
        email,
        gender,
        dob,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function updateIndividualEInvoiceInfo
 * @param {is_CompletedE_Invoice_Info, IcFullName, IcNumber}
 * @description This function is used to update individual e-invoice info
 */

export const updateIndividualEInvoiceInfo = async ({
  is_CompletedE_Invoice_Info,
  IcFullName,
  IcNumber,
  email
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.post(
      `${url}/common/u/update-profile`,
      {
        is_CompletedE_Invoice_Info,
        IcFullName,
        IcNumber,
        email
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function updateCompanyEInvoiceInfo
 * @param {name, regNo, tin, msicCode, sstNo, address, contactNo, email, businessDescription}
 * @description This function is used to update company e-invoice info
 */
export const updateCompanyEInvoiceInfo = async ({
  name,
  regNo,
  tin,
  msicCode,
  sstNo,
  address,
  contactNo,
  email,
  businessDescription,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.patch(
      `${url}/common/u/e-invoiceInfo`,
      {
        name,
        regNo,
        tin,
        msicCode,
        sstNo,
        address,
        contactNo,
        email,
        businessDescription,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function uploadProfileImage
 * @param {file}
 * @description This function is used to upload profile
 */

export const uploadProfileImage = async ({ file }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.post(
      `${url}/upload/user/profile`,
      {
        file,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getPCoinBalanceService
 * @param {file}
 * @description This function is used to get user P-Coin balance
 */

export const getPCoinBalanceService = async ({ filter = "" }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.get(
      `${url}/pCoinsWallet/balance?filter=${filter}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getSettingsService
 * @param {file}
 * @description This function is used to get settings
 */

export const getSettingsService = async ({ key = "" }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.get(`${url}/settings/manage/get?key=${key}`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return result;
  } catch (err) {
    return err;
  }
};

export const bonusClaimService = async (id) => {
  try {
    const tokens = getToken();
    const { tokenAccess } = tokens;
    const result = await axios.patch(
      `${url}/loginBonus/claim?id=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          "Content-Type": "application/json",
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
// user account delete service

export const deleteProfileService = async (data) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/common/u/delete-account`,

      data,

      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,

          "Content-Type": "application/json",
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

// delete Account from app store

export const deleteUserAccAppStoreService = async (data) => {
  try {
    const result = await axios.post(
      `${url}/user/deleteAccOTP`,

      data
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const OtpVerifyDeleteService = async ({ otpType, otp }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.post(
      `${url}/user/deleteAcc/OTPverify`,
      {
        otpType,
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};
