import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { LuEye, LuEyeOff } from "react-icons/lu";

// API-Service
import { changePassword } from "../../service/auth.service";
// Utility-Service
import { removeUserSession } from "../../utils/AuthService";
// Redux-Settings
import { setLogout } from "../../redux/authSlice";
// Components
import PasswordChangedSuccess from "./PasswordChangedSucess";

// images
import { logo } from "../../assets/images/authentication";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";

// Styles
import "../../assets/scss/base/pages/account/ChangePassword.scss";
import "../../assets/scss/base/pages/global.scss";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import Loader from "../../components/micro/loader/Loader";
import { Helmet } from "react-helmet";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordsMatched, setPasswordsMatched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    newPassword: "",
    confirmedPassword: "",
  });
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);
  const [togglePassword3, setTogglePassword3] = useState(false);

  const [passwordOneLowerUpperCase, setPasswordOneLowerUpperCase] =
    useState(false);
  const [passwordOneNumber, setPasswordOneNumber] = useState(false);
  const [passwordOneSpecialCharacter, setPasswordOneSpecialCharacter] =
    useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);
  const [isSuccefullyChange, setIsSuccefullyChange] = useState(false);

  // Password-validation
  let timeoutId;
  const passwordNumberRegex = /^(?=.*\d)/;
  const passwordSpecialRegex = /^(?=.*\W)/;
  const passwordLengthRegex = /^.{8,}$/;
  const passwordLowerUpperRegex = /^(?=.*[a-z])(?=.*[A-Z])/;

  const handlePasswordFieldClicked = () => {
    setPasswordFieldClicked(true);
  };

  const handleSaveDetailsClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (passwordsMatched) {
      const response = await changePassword({
        password: formData.password,
        newPassword: formData.newPassword,
      });
      setIsLoading(false);
      if (response?.data?.success) {
        setIsSuccefullyChange(true);
        navigate("/changepassword/success");
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleIcon} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Successfully change the password</h4>
                <p>Password is successfully changed you can login now</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
        //  navigate('/account/changepassword/success')
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={AlertCircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>{response?.data?.errorMessage}</h4>
                <p>Password can't change due to above error</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Password does not match</h4>
              <p>Please check the passwords</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
    setIsLoading(false);
  };
  const handleShowPasswordOne = () => {
    setTogglePassword(!togglePassword);
  };
  const handleShowPasswordTwo = () => {
    setTogglePassword2(!togglePassword2);
  };
  const handleShowPasswordThree = () => {
    setTogglePassword3(!togglePassword3);
  };
  const handlePasswordInput = (e) => {
    setFormData({
      ...formData,
      password: e.target.value,
    });
  };

  const handleNewPasswordInput = (e) => {
    const newPassword = e.target.value;
    if (formData.confirmedPassword) {
      if (formData.confirmedPassword === e.target.value) {
        setPasswordsMatched(true);
      } else {
        setPasswordsMatched(false);
      }
    }
    setFormData({
      ...formData,
      newPassword,
    });
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setPasswordOneLowerUpperCase(passwordLowerUpperRegex.test(newPassword));
      setPasswordOneSpecialCharacter(passwordSpecialRegex.test(newPassword));
      setPasswordOneNumber(passwordNumberRegex.test(newPassword));
      setPasswordLength(passwordLengthRegex.test(newPassword));
    }, 300);
  };

  const handleNewPasswordConfirmInput = (e) => {
    setFormData({
      ...formData,
      confirmedPassword: e.target.value,
    });
    if (formData.newPassword === e.target.value) {
      setPasswordsMatched(true);
    } else {
      setPasswordsMatched(false);
    }
  };

  return (
    <AccountMenu>
      <Helmet>
        <meta charSet="utf-8" />
        <title > Account | Change Password</title>
        <meta
          name="description"
          content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
        />
      </Helmet>
      <div className="ChangePassword__Main__Container">
        <h5 className="ChangePassword__Main__Heading">Change Password</h5>

        <div className="change__password__parent__block">
          <div className="frameparent5EditPassword change__password__block">
            <div className="header3">
              {/* <img
                className="logo-1-icon"
                alt="logo"
                onClick={() => navigate("/")}
                src={logo}
              />
              <div className="change__password__header">
                <h3>Create New Password</h3>
                <p>Enter new password which is never used before</p>
              </div> */}
            </div>
            <form
              onSubmit={handleSaveDetailsClick}
              className=" dropdown-groupEditPassword"
            >
              <div className="formEditPassword">
                <div className="input-fieldEditPassword">
                  <div className="input-fieldEditPassword">
                    <div className="label4EditPassword">Current Password</div>
                    <div className="InputContainer__Confirm__Password">
                      <input
                        className="input3EditPassword"
                        type={togglePassword ? "text" : "password"}
                        value={formData.email}
                        onChange={(e) => handlePasswordInput(e)}
                        placeholder="Enter Current password"
                        required
                      />
                      {togglePassword ? (
                        <LuEyeOff
                          className="eye-icon-One"
                          onClick={() => handleShowPasswordOne()}
                        />
                      ) : (
                        <LuEye
                          className="eye-icon-One"
                          onClick={() => handleShowPasswordOne()}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-fieldEditPassword">
                  <div className="input-fieldEditPassword">
                    <div className="label4EditPassword">New Password</div>
                    <div className="InputContainer__Confirm__Password">
                      <input
                        className="input3EditPassword"
                        type={togglePassword2 ? "text" : "password"}
                        value={formData.dob}
                        // onInput={validatePasswordConditions}
                        onFocus={handlePasswordFieldClicked}
                        onClick={handlePasswordFieldClicked}
                        onChange={(e) => handleNewPasswordInput(e)}
                        placeholder="Enter a new password"
                      />
                      {togglePassword2 ? (
                        <LuEyeOff
                          className="eye-icon-Two"
                          onClick={() => handleShowPasswordTwo()}
                        />
                      ) : (
                        <LuEye
                          className="eye-icon-Two"
                          onClick={() => handleShowPasswordTwo()}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {passwordFieldClicked && (
                  <div className="hint-text4">
                    <p className="hint-text4-message">
                      Your password must contain:
                    </p>
                    <br />
                    {passwordOneLowerUpperCase ? (
                      <p className="password-lower-upper-green">
                        {" "}
                        ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                      </p>
                    ) : (
                      <p className="password-lower-upper-red">
                        {" "}
                        ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                      </p>
                    )}

                    {passwordOneNumber ? (
                      <p className="password-number-green"> ✔ a number </p>
                    ) : (
                      <p className="password-number-red"> ✔ a number </p>
                    )}

                    {passwordOneSpecialCharacter ? (
                      <p className="password-special-character-green">
                        {" "}
                        ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                      </p>
                    ) : (
                      <p className="password-special-character-red">
                        {" "}
                        ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                      </p>
                    )}

                    {passwordLength ? (
                      <p className="password-length-green">
                        {" "}
                        ✔ at least 8 characters{" "}
                      </p>
                    ) : (
                      <p className="password-length-red">
                        {" "}
                        ✔ at least 8 characters{" "}
                      </p>
                    )}
                  </div>
                )}

                <div className="input-fieldEditPassword">
                  <div className="input-fieldEditPassword">
                    <div className="label4EditPassword">
                      Confirm new password
                    </div>
                    <div className="InputContainer__Confirm__Password">
                      <input
                        className="input3EditPassword"
                        type={togglePassword3 ? "text" : "password"}
                        value={formData.dob}
                        onChange={(e) => handleNewPasswordConfirmInput(e)}
                        placeholder="Confirm new password"
                      />
                      {togglePassword3 ? (
                        <LuEyeOff
                          className="eye-icon-Three"
                          onClick={() => handleShowPasswordThree()}
                        />
                      ) : (
                        <LuEye
                          className="eye-icon-Three"
                          onClick={() => handleShowPasswordThree()}
                        />
                      )}
                    </div>
                    {passwordsMatched && (
                      <p className={"password-lower-upper-green"}>
                        Password is same
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <button type="submit" className="button1EditPassword">
                {isLoading ? "Loading..." : "Change Password"}
              </button>
            </form>
            {/* <div className="row2">
              <div className="text20">Remember password?</div>
              <button
                onClick={() => {
                  navigate("/login");
                }}
                className="buttons8"
              >
                <div className="text21">Login</div>
              </button>
            </div> */}
          </div>
          <Toaster position="top-right" />
        </div>
      </div>
    </AccountMenu>
  );
};

export default ChangePassword;
