import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Label, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Drawer from "react-modern-drawer";
import { CiCircleRemove } from "react-icons/ci";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

// API-Service
import {
  getProductList,
  getProductListWithFilter,
} from "../../service/catalogue.service";
import {
  getBrandsListing,
  getCategoriesListingArray,
} from "../../service/dashboard.service";

// Redux-Settings
import {
  selectCategoriesList,
  setCategoriesList,
} from "../../redux/categoriesSlice";
import {
  selectIsSmallScreen,
  selectCategoryDropdownValue,
} from "../../redux/utilitySlice";
import {
  setProductList,
  setFilteredList,
  setTotalItem,
  selectPageCount,
  selectTotalItem,
} from "../../redux/productSlice";

// Component
import MultiRangeSlider from "../../components/micro/multiRangeSlider/multiRangeSlider";
import CataloguePagination from "../../components/catalogue/CataloguePagination";
import ScrollToTop from "../../utils/ScrollToTop";
// Higher-Order-Component
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";
// Styles
import "react-loading-skeleton/dist/skeleton.css";
import "../../assets/scss/base/pages/homepage/CataloguePage.scss";
import { Helmet } from "react-helmet";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const CataloguePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pages = useSelector(selectPageCount);
  const { state } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const searchQuery = urlParams.get("search");

  const { categoryName, subCategoryName, brandName } = useParams();
  const totalItem = useSelector(selectTotalItem);
  const categories = useSelector(selectCategoriesList);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] =
    useState(subCategoryName);
  const [selectedBrand, setselectedBrand] = useState({});
  const [barndsSelection, setBarndsSelection] = useState([]);
  const [subCategoriesSlection, setSubCategoriesSlection] = useState([]);
  const [subCategories, setSubCategories] = useState();
  const [optionSelection, setOptionSelection] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [maxPriceValue, setMaxPriceValue] = useState(10000);
  const [minPriceValue, setMinPriceValue] = useState(0);

  let timer;

  const handleMinMax = ({ min, max }) => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      setMaxPriceValue(max);
      setMinPriceValue(min);
    }, 1000);
  };
  const toggleDrawer = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const sleceTedCategoryResult = categories?.find(
      (categoryItem) => categoryName === categoryItem?.slug
    );
    if (categoryName || subCategoryName) {
      const sleceTedSubCategoryResult =
        sleceTedCategoryResult?.SubCategory?.find(
          (subCategoryItem) => subCategoryName === subCategoryItem?.slug
        );

      const sleceTedBrandResult =
        sleceTedSubCategoryResult?.CategoryBrands?.find(
          (brandItem) => brandName === brandItem?.Brand?.slug
        );

      const modifiedSubCategoryData = sleceTedCategoryResult?.SubCategory?.map(
        (checkbox) =>
          checkbox.slug === subCategoryName
            ? { ...checkbox, checked: true }
            : { ...checkbox, checked: false }
      );

      const modifiedBrandData =
        sleceTedSubCategoryResult?.CategoryBrands?.map((brandItem) =>
          brandItem?.Brand?.slug === brandName
            ? { ...brandItem, checked: true }
            : { ...brandItem, checked: false }
        ) || [];
      const subCatType =
        sleceTedSubCategoryResult?.SubCategoryType?.map((brandItem) =>
          brandItem?.slug === brandName
            ? { ...brandItem, checked: true }
            : { ...brandItem, checked: false }
        ) || [];


      setSelectedCategory(sleceTedCategoryResult);
      setSelectedSubCategory(sleceTedSubCategoryResult);
      setselectedBrand(sleceTedBrandResult);
      setSubCategoriesSlection(modifiedSubCategoryData);
      setBarndsSelection([...modifiedBrandData, ...subCatType]);
    } else {
      const modifiedSubCategoryData =
        sleceTedCategoryResult?.SubCategory?.map((item) => ({
          checked: false,
          ...item,
        })) || [];

      // const newSubCtegoryData = {
      //   id: "subCategoryAll",
      //   name: "All",
      //   checked: true,
      // };

      setSubCategoriesSlection(modifiedSubCategoryData);
    }
  }, [categoryName, subCategoryName, brandName]);

  // Fetch products
  const handleFetchListing = async () => {
    setLoading(true);
    const response = await getProductList({
      categoryId: categoryName || "",
      brandId: brandName || "",
      subCategoryName: subCategoryName || "",
      subCategoryTypeSlug: brandName || "",
      isOnFlashSale: state?.isOnFlashSale || "",
      search: searchQuery ? searchQuery : "",
      page: pages > 0 ? pages : "",
      maxPrice: maxPriceValue,
      minPrice: minPriceValue,
      sort: optionSelection,
    });
    if (response?.data?.success) {
      dispatch(setProductList(response?.data?.data?.list));
      dispatch(setFilteredList(response?.data?.data?.list));
      dispatch(setTotalItem(response?.data?.data?.count));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      subCategoryName ||
      brandName ||
      maxPriceValue ||
      minPriceValue ||
      optionSelection ||
      searchQuery
    ) {
      handleFetchListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryName,
    subCategoryName,
    brandName,
    maxPriceValue,
    minPriceValue,
    optionSelection,
    searchQuery,
  ]);

  // /**
  //  * @function fetchBarndList
  //  * @param {}
  //  * @description This function is used to fetch catagories
  //  */
  // const fetchBarndList = async () => {
  //     const response = await getBrandsListing({
  //         filter: 'Active',
  //         type: '',
  //         getAll: 'true'
  //     });
  //     if (response?.data?.success) {
  //         setBarndsSelection(response?.data?.data?.list.map(item => ({
  //             checked: false,
  //             id: item?.id,
  //             name: item?.name
  //         })))
  //     }
  //     return response;
  // }

  // useEffect(() => {
  //     fetchBarndList();
  // }, [])
  // Fetch products
  const handleFetchProductBasedOnFilterListing = async (page) => {
    const response = await getProductList({
      categoryId: categoryName || "",
      ...(brandName && {
        brandId: brandName,
      }),
      subCategoryTypeSlug: brandName || "",
      ...(subCategoryName && {
        subCategoryName: subCategoryName,
      }),
      search: searchQuery ? searchQuery?.replaceAll("-", " ") : "",
      page: page,
      maxPrice: maxPriceValue,
      minPrice: minPriceValue,
    });

    if (response?.data?.success) {
      dispatch(setProductList([]));
      dispatch(setFilteredList([]));
      dispatch(setProductList(response?.data?.data?.list));
      dispatch(setFilteredList(response?.data?.data?.list));
      dispatch(setTotalItem(response?.data?.data?.count));
      // toggleDrawer();
    }
  };

  function findMetaDesc(categoryName, subCategoryName, brandName) {
    const category = categories.find((cat) => cat.slug === categoryName);

    if (category) {
      if (!subCategoryName) {
        return { metaDesc: category?.metaDesc, name: category?.name };
      }

      const subCategory = category.SubCategory.find(
        (subCat) => subCat.slug === subCategoryName
      );

      if (subCategory) {
        return { metaDesc: subCategory.metaDesc, name: subCategory.name };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const banner = categories?.filter(
    (item) => item?.slug === categoryName && item?.banner
  );



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{findMetaDesc(categoryName, subCategoryName)?.name}</title>
        <meta
          name="description"
          content={
            findMetaDesc(categoryName, subCategoryName)?.metaDesc ||
            "We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
          }
        />
        <meta
          data-rh="true"
          property="og:description"
          name="og:description"
          content={
            findMetaDesc(categoryName, subCategoryName)?.metaDesc ||
            "We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
          }
        />
      </Helmet>
      <ScrollToTop />
      <div className="catalogue__page__block">
        {banner[0]?.banner && (
          <div className="catalogue__page__banner">
            <img
              src={`${imageCDN}/${banner[0]?.banner}`}
              alt="bannerimage"
              style={{ width: "100%" }}
            />
          </div>
        )}
        <div className="brands__info__block">
          {/* <span
            className="brands__info__block--name"
            style={{ marginRight: "0.5rem" }}
          >
            {brandName}
          </span> */}
          <span className="brands__info__block--itemsCount">
            {" "}
            {totalItem} Items
          </span>
        </div>
        <div className="catalogue__page__category">
          {isSmallScreen ? (
            <div>
              <button className="filter__toggle__btn" onClick={toggleDrawer}>
                Filter
              </button>
              <Drawer
                open={isSidebarOpen}
                onClose={toggleDrawer}
                direction="left"
                lockBackgroundScroll={true}
                className="productFilter__drawer__block__navbar"
              >
                <CiCircleRemove
                  onClick={toggleDrawer}
                  className="bannerType-menu-icon"
                />
                <div className="catalogue__page__sidebar">
                  <div className="catalogue__page__filter__category">
                    <h3>Sub Categories</h3>
                    {/* <div className="catalogue__page__filter__category__item">
                      <Input
                        type="checkbox"
                        name="All"
                        checked={true}
                        onChange={handleSubCategoryCheckboxChange}
                      />{" "}
                      <Label check>All</Label>
                    </div> */}
                    {subCategoriesSlection?.map((subCategoriesSlectionItem) => (
                      <div
                        key={subCategoriesSlectionItem?.id}
                        className="catalogue__page__filter__category__item"
                      >
                        <Input
                          type="checkbox"
                          name={subCategoriesSlectionItem?.id}
                          checked={
                            subCategoriesSlectionItem?.slug === subCategoryName
                          }
                          onChange={() => {
                            navigate(
                              `/catalogue/${categoryName}/${subCategoriesSlectionItem?.slug}`
                            );
                            setSelectedSubCategory(
                              subCategoriesSlectionItem?.slug
                            );
                          }}
                        />{" "}
                        <Label check>{subCategoriesSlectionItem?.name}</Label>
                      </div>
                    ))}
                  </div>
                  {/* {barndsSelection?.length > 0 && <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                                        <h3>Brands</h3>
                                        {barndsSelection.map(barndsSlectionItem => 
                                            <div key={barndsSlectionItem?.id} className="catalogue__page__filter__category__item">
                                                <Input
                                                    type="checkbox"
                                                    name={barndsSlectionItem?.Brand?.id}
                                                    checked={barndsSlectionItem.checked}
                                                    onChange={handleBrandCheckboxChange}
                                                />
                                                {' '}
                                                <Label check>
                                                    {barndsSlectionItem?.Brand?.name}
                                                </Label>
                                            </div> 
                                        )}
                                    </div>} */}
                  <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                    <h3>Price</h3>
                    <MultiRangeSlider
                      min={0}
                      max={10000}
                      onChange={({ min, max }) => {
                        handleMinMax({ min, max });
                      }}
                    />
                  </div>
                  <div className="filter__btn__block">
                    <button
                      onClick={handleFetchProductBasedOnFilterListing}
                      className="filter__btn"
                    >
                      Apply Filter
                    </button>
                    <button
                      // onClick={handleClearFilter}
                      className="clear_all__btn"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
              </Drawer>
            </div>
          ) : (
            <h3>Filters</h3>
          )}
          <div className="sorting__filter__block">
            <Label for="exampleSelect">Sort By :</Label>
            <Input
              id="exampleSelect"
              name="select"
              type="select"
              onChange={(e) => setOptionSelection(e?.target?.value)}
            >
              <option value="latest">Latest</option>
              <option value="PriceAsc">Price Ascending</option>
              <option value="PriceDesc">Price Descending</option>
            </Input>
          </div>
        </div>
        <div className="catalogue__page__main__block">
          <div
            className={`${!isSmallScreen
                ? "catalogue__page__sidebar"
                : "catalogue__page__sidebar display__none"
              } `}
          >
            <div className="catalogue__page__filter__category">
              <h3>Sub Categories</h3>
              <div className="catalogue__page__filter__category__item">
                <Input
                  type="checkbox"
                  checked={!subCategoryName && true}
                  onChange={() => {
                    navigate(`/catalogue/${categoryName}`);
                    // setSelectedSubCategory(subCategoriesSlectionItem?.slug);
                  }}
                />{" "}
                <Label check>All</Label>
              </div>
              {subCategoriesSlection?.map((subCategoriesSlectionItem) => (
                <>
                  <div
                    key={subCategoriesSlectionItem?.id}
                    className="catalogue__page__filter__category__item"
                  >
                    <Input
                      type="checkbox"
                      name={subCategoriesSlectionItem.id}
                      checked={
                        subCategoriesSlectionItem?.slug === subCategoryName
                      }
                      onChange={() => {
                        navigate(
                          `/catalogue/${categoryName}/${subCategoriesSlectionItem?.slug}`,
                          {
                            state: {
                              id: subCategoriesSlectionItem?.parentId,
                              subCatId: subCategoriesSlectionItem?.id,
                              categoryName: categories?.name,
                              subCatagoryName: subCategoriesSlectionItem?.name,
                              SubCategory: categories?.SubCategory,
                            },
                          }
                        );
                        setSelectedSubCategory(subCategoriesSlectionItem?.slug);
                      }}
                    />{" "}
                    <Label check>{subCategoriesSlectionItem?.name}</Label>
                  </div>
                </>
              ))}
            </div>
            <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
              <h3>Brand & Category Type </h3>
              <div className="catalogue__page__filter__category__item">
                <Input
                  type="checkbox"
                  checked={brandName ? false : true}
                  onChange={() => {
                    navigate(
                      `/catalogue/${categoryName}/${subCategoryName}`,
                      {}
                    );
                    // setSelectedSubCategory(subCategoriesSlectionItem?.slug);
                  }}
                />{" "}
                <Label check>All</Label>
              </div>
              {barndsSelection?.map((barndsSlectionItem) => {
                const brand = barndsSlectionItem?.Brand || barndsSlectionItem; // Handle both cases
                return (
                  <div
                    key={barndsSlectionItem?.id}
                    className="catalogue__page__filter__category__item"
                  >
                    <Input
                      type="checkbox"
                      name={brand?.id}
                      checked={brand?.slug === brandName}
                      onChange={() =>
                        navigate(
                          `/catalogue/${categoryName}/${subCategoryName}/${brand?.slug}`
                        )
                      }
                    />
                    <Label check>{brand?.name}</Label>
                  </div>
                );
              })}
            </div>

            <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
              <h3>Price</h3>
              <MultiRangeSlider
                min={0}
                max={10000}
                onChange={({ min, max }) => {
                  handleMinMax({ min, max });
                }}
              />
              {/* <div className="filter__btn__block">
                <button
                  onClick={handleFetchProductBasedOnFilterListing}
                  className="filter__btn"
                >
                  Apply Pricing
                </button>
                <button onClick={handleClearFilter} className="clear_all__btn">
                  Clear all
                </button>
              </div> */}
            </div>
          </div>
          <div className="catalogue__page__products__block">
            <div className="catalogue__page__products__block__wrapper">
              {loading ? (
                <div className="catalogueProductsContainerMain">
                  {[1, 2, 3, 4, 5, 6, 7, 8]?.map((placeHolderItem) => (
                    <div
                      key={placeHolderItem}
                      className="catalouge__product__item"
                    >
                      <Skeleton height={150} />
                      <Skeleton height={25} />
                      <Skeleton count={3} />
                    </div>
                  ))}
                </div>
              ) : (
                <CataloguePagination
                  handleFetchProductBasedOnFilterListing={
                    handleFetchProductBasedOnFilterListing
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default withLayout(CataloguePage, {
  isProductPage: true,
  isNestedPageName: true,
});
