import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import toast, { Toaster } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineFavoriteBorder, MdFavorite } from "react-icons/md";
import { FiShoppingBag } from "react-icons/fi";

// API-Service
import { addCartService, cartListService } from "../../service/cart.service";

// Utility
import { priceCalculator } from "../../utils/Utility";

// Redux-Settings
import { isLoggedIn } from "../../redux/authSlice";
import { setIsLoginPopup, selectIsLoginPopup } from "../../redux/utilitySlice";
import {
  setCartList,
  setSelectedCartItemsList,
  setSelectedCartItemsId,
} from "../../redux/cartSlice";

// Images
import { pcoin } from "../../assets/images/homepage";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";

// Component
import LoginMessageModal from "../modal/LoginMessageModal";

// Styles
import "../../assets/scss/base/pages/product/product.scss";
import { addWishlistService } from "../../service/wishlist.service";
import { setLoginModalToggle } from "../../redux/modalsSlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const Product = memo(({ product }) => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggdin = useSelector(isLoggedIn);
  const isLoginMessageModal = useSelector(selectIsLoginPopup);
  const [isAddedCartlist, setIsAddedCartlist] = useState(false);
  const [isAddedWishlist, setIsAddedWishlist] = useState(false);
  useEffect(() => {
    setIsAddedWishlist(product?.isOnWishlist);
    setIsAddedCartlist(product?.isOnCart);
  }, [product]);

  const fetchCartList = async () => {
    const response = await cartListService({ filter: "" });
    if (response?.data?.success) {
      dispatch(setCartList(response?.data?.data?.list));
      dispatch(setSelectedCartItemsList(response?.data?.data?.list));
      dispatch(
        setSelectedCartItemsId(
          response?.data?.data?.list?.map((item) => item?.id)
        )
      );
    }
  };
  const handleAddProductToCart = async () => {
    // Track a custom event
    ReactGA.event({
      category: "Button Click",
      action: "Clicked on Add to product",
      label: "Product list Page",
    });
    // Continue with your button's click handler
    if (product?.SingleVariation?.stock !== 0) {
      const response = await addCartService({
        productId: product?.id,
        productVariationId: product?.SingleVariation?.id,
      });
      if (response?.data?.success) {
        setIsAddedCartlist(true);
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleIcon} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Successfully added to cart!</h4>
                <p>This product is successfully added to cart list</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
        fetchCartList();
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={AlertCircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>{response?.data?.errorMessage}</h4>
                <p>This product is not added to cart list</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Product out of stock</h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  //add to wishlist
  const handleAddProductToWishlist = async () => {
    const response = await addWishlistService({
      productId: product?.id,
      productVariationsId: product?.SingleVariation?.id,
    });

    if (response?.data?.success) {
      setIsAddedWishlist(response?.data?.success);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Item added into your wishlist!</h4>
              <p>This item is successfully added to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              <p>This product is not added to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  return (
    <div className="product__block">
      <div
        className="product__thumb__block"
        onClick={() => {
          navigate(`/product/${product?.slug}`, {
            state: { ...state, productId: product?.id },
          });
          ReactGA.event({
            category: "Button Click",
            action: "Product clicks/selections",
            label: "Product list Page",
          });
        }}
      >
        {product?.thumbnail ? (
          <img
            src={`${imageCDN}/${product?.thumbnail}`}
            alt="P-coin products"
          />
        ) : (
          <img
            src={ProductPlaceHolder}
            alt="P-coin products"
            style={{ width: "100%" }}
          />
        )}
      </div>
      <div className="product__content__block">
        <div onClick={() => navigate(`/product/${product?.slug}`)}>
          <h3 className="product__title">
            {product?.name}

          </h3>
        </div>
        <div className="product__content__details__block">
          <div className="product__price__block">
            <span
              className="product__price--after--discount"
              style={{ fontSize: "13px", display: "flex", fontWeight: "5P00" }}
            >
              {!product?.isOnRedemption && (
                <p style={{ fontSize: "10px" }}>RM</p>
              )}
              {Number(
                priceCalculator({
                  price: product?.SingleVariation?.price,
                  discountPrice: product?.SingleVariation?.discount,
                })
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {product?.isOnRedemption && "P-Coins"}
            </span>
            {Number(product?.SingleVariation?.discount) > 0 && (
              <span
                className="product__price--original"
                style={{ fontSize: "10px" }}
              >
                <s style={{ color: "grey" }}>
                  RM
                  {product?.SingleVariation?.price.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </s>
              </span>
            )}

            {Number(product?.SingleVariation?.discount) > 0 ? (
              <span
                className="product__price--discount-percantage"
                style={{ fontSize: "10px" }}
              >
                ({Math.floor(product?.SingleVariation?.discount)}%{" "}
                <span>OFF</span>)
              </span>
            ) : null}
          </div>
          <div className="product__coin__block">
            <span className="product__coin--title" style={{ fontSize: "11px" }}>
              You can earn
            </span>
            <span className="product__coin">
              <img src={pcoin} alt="" />
              <span
                style={{ fontSize: "11px" }}
                className="product__coin--text"
              >
                {product?.SingleVariation?.earningPcoins} P-Coins
              </span>
            </span>
          </div>
          <div className="product__action__block">
            {isAddedCartlist ? (
              <button className="product__cart__btn product__cart__btn__disable">
                Added to cart
              </button>
            ) : (
              <button
                className="product__cart__btn"
                onClick={() => {
                  if (isLoggdin) {
                    handleAddProductToCart();
                  } else {
                    // dispatch(setIsLoginPopup(true))
                    dispatch(setLoginModalToggle(true));
                  }
                }}
              >
                <FiShoppingBag />
                Add to cart
              </button>
            )}
            <button className="product__wishlist__btn">
              {isAddedWishlist ? (
                <MdFavorite style={{ color: "#D71513" }} />
              ) : (
                <MdOutlineFavoriteBorder
                  onClick={() =>
                    isLoggdin
                      ? handleAddProductToWishlist()
                      : dispatch(setLoginModalToggle(true))
                  }
                />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* <Toaster position="top-right" /> */}
      {isLoginMessageModal && <LoginMessageModal />}
    </div>
  );
});

export default Product;
