import React, { memo, useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiMenu2Fill } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
// import "../../assets/scss/base/pages/homepage/CategoriesContainer.scss";
import { BiCategory } from "react-icons/bi";
import { getBannersList } from "../../service/dashboard.service";

// Redux
import { selectCategoriesList } from "../../redux/categoriesSlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const CategoriesContainer = memo(() => {
  const categoriesArray = useSelector(selectCategoriesList);
  //  const [categoriesArray, setCategoriesArray] = useState([]);
  const [catBtnClicked, setCatBtnClicked] = useState(false);
  const [bannerType, setBannerType] = useState("Dashboard");
  const [apiBannerImage, setApiBannerImage] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [categoryClick, setCategoryClick] = useState(false);
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bannerMenuClick, setBannerMenuClick] = useState(false);
  const isSmallScreen = windowWidth <= 900;
  const [subCategoryChildren, setSubCategoryChildren] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await getBannersList({
          filter: "",
          type: bannerType,
        });

        if (response?.data?.data?.list[0]?.file !== null) {
          setApiBannerImage(response?.data?.data?.list[0]?.file);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchBanner();
  }, [bannerType]);

  const handleNewArrivalClick = () => {
    setBannerType("New_Arrival");
  };

  const handleTrendingClick = () => {
    setBannerType("Trending");
  };

  const handleBestSellingClick = () => {
    setBannerType("Best-Selling");
  };

  const handleSuperSaverClick = () => {
    setBannerType("Super_Saver");
  };

  const bannerImage = `${url}/${apiBannerImage}`;

  // const handleCatBtnClicked = async (e) => {
  //   e.preventDefault();
  //   setCatBtnClicked(true);

  //   const response = await getCategoriesListing({
  //     filter: 'trending',
  //   });

  // }

  const handleCatBtnClick = () => {
    setCatBtnClicked(!catBtnClicked);
  };

  const colorPalette = [
    "#FCE9D8",
    "#DEE2EB",
    "#F8D2D1",
    "#E0D4E2",
    "#C9F8F5",
    "#D9DAD4",
    "#8DE5E7",
    "#D9DAD4",
    "#F6E3B9",
  ];

  const handleCatClick = (id) => {
    const subcategory = categoriesArray?.filter(
      (category) => category.id === id
    );
    setSubCategoryArray(subcategory);
    const subcategorylist = subcategory[0]?.SubCategory;
    setSubCategory(subcategorylist);
    setCategoryClick(true);
  };

  const handleSubCatClick = (id) => {
    const subCategoryChildrenArray = subCategory?.filter(
      (subcategory) => subcategory.id === id
    );
    const subCategoryChilrenList = subCategoryChildrenArray[0].SubCategoryType;
    setSubCategoryChildren(subCategoryChilrenList);
  };

  const renderSubCategoryChildren = (
    <div className="catTabMainSubCat">
      <div className="catTabInner">
        {subCategoryChildren &&
          subCategoryChildren?.map((subcategorychild) => {
            return (
              <div key={subcategorychild.id}>
                <button className="catTabCat">
                  <div className="catTabImageNameMain">
                    <p>{subcategorychild.name}</p>
                  </div>
                  <MdKeyboardArrowDown className="catTabIcon" />
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );

  const renderSubCategories = (
    <div className="catTabMainSubCat">
      <div className="catTabInner">
        {subCategory &&
          subCategory?.map((subcategory) => {
            return (
              <div
                onClick={() => handleSubCatClick(subcategory.id)}
                key={subcategory.id}
              >
                <button className="catTabCat">
                  <div className="catTabImageNameMain">
                    <p>{subcategory.name}</p>
                  </div>
                  <MdKeyboardArrowDown className="catTabIcon" />
                </button>
              </div>
            );
          })}
        {subCategoryChildren && renderSubCategoryChildren}
      </div>
    </div>
  );

  const renderCatListTab = (
    <div className="catTabMain">
      <div className="catTabInner">
        {categoriesArray &&
          categoriesArray?.map((category, index) => {
            const bgColor = colorPalette[index % colorPalette.length];
            return (
              <div key={category.id}>
                <button
                  onClick={() => handleCatClick(category.id)}
                  style={{ backgroundColor: bgColor }}
                  className="catTabCat"
                >
                  <div className="catTabImageNameMain">
                    <div className="catTabImageDiv">
                      <img
                        alt="img"
                        src={`${url}/${category.file}`}
                        className="catItemImage"
                      />
                    </div>
                    <p>{category.name}</p>
                  </div>
                  <MdKeyboardArrowDown className="catTabIcon" />
                </button>
              </div>
            );
          })}

        {subCategory && renderSubCategories}
      </div>
    </div>
  );

  const handleBannerMenuClick = () => {
    setBannerMenuClick(!bannerMenuClick);
  };

  const renderBannersTab = (
    <div>
      <div className="bannersTabMain">
        <button
          onClick={handleNewArrivalClick}
          className="nav-item-base1CatTab"
        >
          <div className="content4CatTab">
            <div className="text11CatTab">New Arrival</div>
          </div>
        </button>
        <button onClick={handleTrendingClick} className="nav-item-base1CatTab">
          <div className="content4CatTab">
            <div className="text11CatTab">Trending</div>
          </div>
        </button>
        <button
          onClick={handleBestSellingClick}
          className="nav-item-base1CatTab"
        >
          <div className="content4CatTab">
            <div className="text11CatTab">Best Selling</div>
          </div>
        </button>
        <button
          onClick={handleSuperSaverClick}
          className="nav-item-base1CatTab"
        >
          <div className="content4CatTab">
            <div className="text11CatTab">Super Saver</div>
          </div>
        </button>
        <div className="nav-item-base5CatTab">
          <div className="content4CatTab">
            {/* <img alt='logo' src={pcoinLogo}  /> */}
            <div className="text9CatTab">Redemption Corner</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="container1Cat">
        <div className="nav-item-baseCat">
          <div className="content4Cat">
            <button onClick={handleCatBtnClick} className="categoryCat">
              <BiCategory className="category-childCat" alt="icon" />
              <div className="text9Cat">
                {isSmallScreen ? "" : "Categories"}
              </div>
            </button>
          </div>
        </div>

        {catBtnClicked && renderCatListTab}

        {isSmallScreen ? (
          <>
            <div className="input-dropdownHeader">
              <CiSearch className="search-icon" />
              <input
                placeholder="Search"
                name="search"
                type="text"
                className="search-input"
              />
              {/* <div className="hint-textHeader">This is a hint text to help user.</div> */}
            </div>
            <div>
              <RiMenu2Fill
                onClick={handleBannerMenuClick}
                className="bannerType-menu-icon"
              />
            </div>
            {bannerMenuClick && renderBannersTab}
          </>
        ) : (
          <div className="navigationCat">
            <nav className="nav-item-base-parentCat">
              <button
                onClick={handleNewArrivalClick}
                className="nav-item-base1Cat"
              >
                <div className="content4Cat">
                  <div className="text11Cat">New Arrival</div>
                </div>
              </button>
              <button
                onClick={handleTrendingClick}
                className="nav-item-base1Cat"
              >
                <div className="content4Cat">
                  <div className="text11Cat">Trending</div>
                </div>
              </button>
              <button
                onClick={handleBestSellingClick}
                className="nav-item-base1Cat"
              >
                <div className="content4Cat">
                  <div className="text11Cat">Best Selling</div>
                </div>
              </button>
              <button
                onClick={handleSuperSaverClick}
                className="nav-item-base1Cat"
              >
                <div className="content4Cat">
                  <div className="text11Cat">Super Saver</div>
                </div>
              </button>
            </nav>
            <div className="nav-item-base5Cat">
              <div className="content4Cat">
                {/* <img alt='logo' src={pcoinLogo}  /> */}
                <div className="text9Cat">Redemption Corner</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {bannerImage && (
        <div>
        <img alt='dashboard-banner' src={bannerImage} className="dashboard-banner-img" />
      </div>
      )} */}
    </>
  );
});

export default CategoriesContainer;
